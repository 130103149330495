import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import imgProfile from "../../../images/img-profile.png";
import brandLogo from "../../../images/brand-logo.svg";
import icMenu from "../../../images/ic-menu.svg";
import logoutIcon from "../../../images/logout-icon.svg";
import passsword from "../../../images/password.svg";
import profileIcon from "../../../images/ic-user.svg";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../store/actioncreator";

import { useHistory } from "react-router-dom"
import CheckableTag from "antd/es/tag/CheckableTag";

const Header = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const {isRerenderHeader} = props
  const location = useLocation()
  const pathname = location?.pathname?.split("/")[1]

  const [menuactive, setactive] = useState(false);
  const arr = ["/traininglesson", "/quiz", "/quizretake"];
  const getlocation = window.location.pathname;
  const isShow = arr.includes(getlocation);
  const [show, setShow] = useState(false);
  const [menuopen, setmenu] = useState(false);
  const [name, setName] = useState("");

  // useEffect(() => {
  //   fetchName();
  // }, []);

  useEffect(()=>{
    fetchName();
  },[isRerenderHeader])

  const fetchName = async () => {
    try{
    const { value } = await props.user();
    if (value?.success) {
      if(value?.user?.role === "operator" || value?.user?.role === "manager"){
      setName(value);
    }
    else {
      navigate("/login")
    }
  } 
  else {
    if (value?.message === "Authentication token expired | Unauthorized") {
      navigate("/login")
    }
    dispatch(setAlert('error', value?.message))
  }
}catch(error){
  dispatch(setAlert('error', error?.message))
}
  };

  let bodyclick = "";

  const openmenu = () => {
    setmenu(menuopen ? false : true);
    myFunction();
  };

  function myFunction() {
    var element = document.getElementById("profile-wrapper");
    element.classList.toggle("left-wrapper");
  }
 
  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    const checkAuth=localStorage.getItem('token')
        if(checkAuth===null){
          window.location.pathname='/login'
        }

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent)) {

      setmenu(true)
    }
    var a = document.querySelectorAll(".navbar-nav a");
    for (var i = 0, length = a.length; i < length; i++) {
      a[i].onclick = function () {
        var b = document.querySelector(".navbar-nav li.active");
        // if (b) b.classList.remove("active");
        // this.parentNode.classList.add("active");
      };
    }
  }, [menuactive]);

  const logout = () => {  
    localStorage.removeItem('token');
    // window.location.href = '/login'; 
  };
  

  return (
    <>
      <header className="Header">
        <nav className="navbar  navbar-dark">
          <div className="container-fluid p-0">
            <div
              className={
                menuopen
                  ? "primary-menu-container "
                  : "primary-menu-container showmenu"
              }
              onClick={openmenu}
            >
              <div className="sidebar-container"  onClick={(e) => e.stopPropagation()}>
                <ul className="navbar-nav me-auto">
                  <li className={pathname === "dashboard" ? "nav-item active" : "nav-item"} id="dashboard" >
                    <Link
                      className="nav-link "
                      to="/dashboard"
                    // onClick={gotoYourlots}
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li className={pathname === "lots" ? "nav-item active" : "nav-item"}>
                    <Link
                      className="nav-link "
                      to="/lots"
                    // onClick={gotoYourlots}
                    >
                      Your Lots
                    </Link>
                  </li>
                  <li className={pathname === "report" ? "nav-item active" : "nav-item"} >
                    <Link className="nav-link" to="/report">
                      Reports
                    </Link>
                  </li>
                  <li className={pathname === "operatorcitations" ? "nav-item active" : "nav-item"} >
                    <Link
                      className="nav-link "
                      to="/operatorcitations"
                      >
                      Tags Deployed
                    </Link>
                  </li>
                  {/* <li className={pathname === "operator-mail-in-citations" ? "nav-item active" : "nav-item"} >
                    <Link
                      className="nav-link "
                      to="/operator-mail-in-citations"
                    >
                      Mail-In Tags Deployed
                    </Link>
                  </li> */}
                  {
                    name?.user?.role === "operator" &&
                    <>
                  <li className={pathname === "connectWithStripe" ? "nav-item active" : "nav-item"}>
                    <Link className="nav-link" to="/connectWithStripe">
                      Connect with Stripe
                    </Link>
                  </li>
                  </>
                  }
                  <li className={pathname === "permit" ? "nav-item active" : "nav-item"} >
                    <Link className="nav-link" to="/permit">
                      Permits
                    </Link>
                  </li>
                  {
                    name?.user?.role === "operator" &&
                    <>
                  <li className={pathname === "offence" ? "nav-item active" : "nav-item"}>
                    <Link className="nav-link" to="/offence">
                   Notices
                    </Link>
                  </li>
                  </>
                  }
                  <li className={pathname === "disputed-citations" ? "nav-item active" : "nav-item"}>
                    <Link className="nav-link" to="/operator/disputed-citations">
                      Disputed Citations  
                    </Link>
                  </li>
                  <li className={pathname === "support" ? "nav-item active" : "nav-item"} >
                    <Link
                      className="nav-link"
                      to="/support"
                      onClick={handleShow}
                      >
                      Contact Support
                    </Link>
                  </li>
                    {
                      name?.user?.role === "operator" &&
                      <>
                  <li className={pathname === "taggr_activities" ? "nav-item active" : "nav-item"} >
                    <Link
                      className="nav-link"
                      to="/taggr_activities"
                      onClick={handleShow}
                    >
                      Visits
                    </Link>
                  </li>
                  <li className={pathname === "managers" ? "nav-item active" : "nav-item"} >
                    <Link
                      className="nav-link"
                      to="/managers"
                      onClick={handleShow}
                      >
                      Create Users
                    </Link>
                  </li>
                  </>
                  }
                </ul>
              </div>
              </div>
              <div className="MenuWidth">
              <button
                id="menu--btn"
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#mynavbar"
                onClick={openmenu}
                >
                {/* <span className="navbar-toggler-icon"></span> */}
                <img src={icMenu} alt="" />
              </button>
            </div>
            
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-primary dropdown-toggle"
                data-bs-toggle={!isShow ? "dropdown" : ""}
                >
                <div>
                  {/* <img src={imgProfile} alt="" className="ProfileImage" /> */}
                  <img src={name?.user?.avatar} alt="" className="ProfileImage" />
                </div>
                <div className="dropdown-text">
                  {/* <h5>Tagger Name</h5> */}
                  <h5>
                    {name?.user?.fullname}
                  </h5>
                  <h6>{name?.user?.email}</h6>
                </div>
                {!isShow && <span className="dropdown-toggle-arrow"></span>}
              </button>
              <ul className="dropdown-menu">
              <li>
                  <Link to="/profile" className="dropdown-item">
                    Profile
                    <img src={profileIcon} alt="" />
                  </Link>
                </li>
                <li>
                  <Link to="/change_password" className="dropdown-item">
                    Change Password
                    <img src={passsword} alt="" />
                  </Link>
                </li>
                <li>
                <Link onClick={logout } to="/login" className="dropdown-item">
                Logout  <img src={logoutIcon} alt="" />                    
                  </Link>                 
                </li>
              </ul>
            </div>
          </div>
          <Link className="navbar-brand"  to="/dashboard">
              {" "}
              <img src={brandLogo} className="BrandLogo" alt="taggr logo" />
            </Link>
        </nav>
      </header>
    </>
  );
};
export default Header;
