
// import React from 'react'
import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import IcClose from "../../images/ic-close.svg";
import Delete from "../../images/delete-popup.svg";
import close from "../../../src/images/Close.svg"
import './modal.css'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Refund_modal = ({ onClose, setReason, reason, show, onSubmit,refund_role, title, header_title, amount,taggrFee, role,setrefund_role, setTaggrFee,operator_name, ...rest }) => {
  // const [taggrFee, setTaggrFee] = useState(false);
  // const [refund_role, setrefund_role] = useState();
  const [activeButton, setActiveButton] = useState(null);
  const [displayCard, setDisplayCard] = useState(false)

  const stripe = useStripe();
  const elements = useElements();

  if (!stripe && !elements) {
    console.log("stripe script didn't loaded!")
    return
  }

  const clearCardElementData = () => elements.getElement(CardElement)?.clear()

  const onVoidSubmit = async () => {
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });
    if(!error){
      clearCardElementData()
      const { id } = paymentMethod
      setDisplayCard(false)
      onSubmit(id)
    }
  }

  const WithholdTaggrCheckbox = ({checkBoxLabel, taggrFee, setTaggrFee}) => (
    <div className="mt-3 text-start">
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="flexCheckDefault"
          checked={taggrFee}
          onChange={() => {
            setTaggrFee(!taggrFee);
          }}
        />
        <label className="form-check-label" for="flexCheckDefault">
          {checkBoxLabel}
        </label>
      </div>
    </div>
  );

  return (
    <div>
      <div className={`modalContainer ${show ? "show" : ""}`} 
        onClick={() => {
          clearCardElementData()
          setDisplayCard(false)
          onClose()
        }}>
        <div className="modalContent" onClick={(e) => e.stopPropagation()}>
          <div className="modaHeader">
            <h5 className="text-center">{header_title} Notice</h5>
            <button onClick={() => {
                clearCardElementData()
                setDisplayCard(false)
                onClose()
              }} className='RefundCloseHeader'><img src={close} /></button>
          </div>
          <div className="modalBody">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h5>Notice number:</h5>
              <p className="m-0">#{title}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h5>Amount:</h5>
              <p className="red m-0">${amount}</p>
            </div>
            {(header_title !== 'Void' && header_title !== 'Reduce' && header_title !== 'Deny' && role !== 'operator') && (
              <div className="mb-4">
                <h5 className="text-start">Issued by:</h5>
                <div className="d-flex MargMinus">
                  <button
                  //  className="btn PrimaryBtn Outline"
                  className={`btn PrimaryBtn Outline ${refund_role === "operator" ? "active_role" : ""}`}
                    onClick={() => {
                      setrefund_role("operator");
                      console.log(refund_role,"setrefund_role-inside modal code---->>>");
                    }}
                  >
                    {operator_name}
                  </button>
                  <button 
                  // className="btn PrimaryBtn Outline"
                  className={`btn PrimaryBtn Outline ${refund_role === "admin" ? "active_role" : ""}`}
                    onClick={() => {
                      setrefund_role("admin");
                      console.log(refund_role,"setrefund_role-inside modal code---->>>");
                    }}
                  >
                    Taggr solutions
                  </button>
                </div>
                <WithholdTaggrCheckbox 
                  checkBoxLabel="Withhold Taggr's Fees"
                  taggrFee={taggrFee}
                  setTaggrFee={setTaggrFee}
                />
              </div>
            )}
            {header_title === 'Void' && rest?.citation_type === "tag_vehicle" && 
             <div className="d-flex mb-4">
              <button className={`btn border`} onClick={() => setDisplayCard(!displayCard)}>
              {displayCard ? 'Remove Card': 'Add Card +'}
             </button>
            </div>
            }
            {header_title === 'Void' && rest?.citation_type === "tag_vehicle" && displayCard &&
             <CardElement options={{
               hidePostalCode: true,
              }}/>
            }
            {
              header_title === "Void" && 
              rest?.citation_type === "mail_in" &&
              <div className='mb-3'>
                <WithholdTaggrCheckbox 
                  checkBoxLabel="Withhold 1099 amount"
                  taggrFee={taggrFee}
                  setTaggrFee={setTaggrFee}
                  />
              </div>
            }
            <div className={`d-flex justify-content-between align-items-center mb-4 ${header_title === 'Void' && rest?.citation_type === "tag_vehicle" ? 'mt-4': ""}`}>
              <h5>Reason:</h5>
              <p className="m-0"></p>
            </div>
            <div className="TextareaWrapper">
              {/* <p className='m-0 text-start'>Damaged item received, partial refund issued</p> */}
              <textarea onChange={setReason} value={reason} />
            </div>
          </div>
          <div className="modalFooter">
            <button className="btn PrimaryBtn" onClick={() => header_title === "Void" && displayCard ? onVoidSubmit() : onSubmit() }>
              Confirm
            </button>
            {/* <button className="btn PrimaryBtn" onClick={onClose}>
              Cancel
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function (props) {
  return (
    <Elements stripe={stripePromise}>
      <Refund_modal {...props}/>
    </Elements>
  )
}