import React, { useReducer, useRef } from "react";
import Modal from "react-modal";
import { Link, useSearchParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Pagination, Tooltip } from 'antd';
import searchicon from "../../../images/search.svg";
import location from "../../../../src/images/location.svg";
import cpma from "../../../images/cpma.svg";
import View from "../../../images/IC-view.svg";
import Loading from "../../../utils/loading/Loading";
import DarkView from "../../../images/DarkViewImg.svg";
import WhiteView from "../../../images/WhiteViewImg.svg";

import leftArrow from "../../../images/left-arrow.svg";
import Header from "../Citations-Header/container";
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { setAlert, setLoading } from "../../../store/actioncreator";
import { useDispatch } from "react-redux";
import { capitalize, debounce } from "../../../global";
import moment from "moment-timezone";
import Confirm_Modal from '../../../utils/modal'
import Refund_modal from "../../../utils/refund_modal";
import TextModal from "../../../utils/text_modal";
import { CSVLink } from "react-csv";
import Download from "../../../components/svgIcons/Download";
import "./styles.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";

export default function PayoutCitationsComponent(props) {
  const csvLink = useRef();
  const tz = moment.tz?.guess()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [reason, setReason] = useState('')
  const [showTextModal, setShowTextModal] = useState(false);
  const [showPagination, setShowPagination] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [startDate, setStartDate] = useState(new Date(searchParams.get('start_date')) || new Date());
  const [endDate, setEndDate] = useState(new Date(searchParams.get('end_date')) || new Date());
  const [role, setrole] = useState(searchParams.get('role'));
  const [userId, setUserId] = useState(searchParams.get('userId'));


  useEffect(() => {
    allCitationsinfo(1)
    var element = document.getElementById("profile-wrapper");
    var menu = document.getElementById("test_menu");
    element.classList.add("left-wrapper");
    menu.classList.remove("showmenu");
    // handleDateSubmit();
    // fetchName()
  }, []);

  const fetchName = async () => {
    // dispatch(setLoading(true))
    try {
      const { value } = await props.user();
      setrole(value?.user?.role)
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setAlert('error', error?.message))
    }
  };

  // console.log("role", role);

  const noticeFields = [
    "Tag Type",
    "Notice Number",
    "Lot",
    "Vehicle Plate",
    "State",
    "Make",
    "Status",
    "Amount",
    "Payment Date",
    "Issued By",
    "Notice Issue Date",
    "Taggr Amount",
    "Operator Amount",
    "TireTag Amount",
    "Taggr Solutions Amount",
    "Dock Fees",
    "Total Stripe Fees",
    "Call Center Fees",
    'Taggr Payout Transfer Group',
    'Payout Transfer Group'
  ];

  const columns =[
    { 
      field: "tiretag_citation_data",
      headerName: "Notice Number",
      width: 200,
      hideable: false,
      editable: false,
      valueFormatter: (val, row) => `${row?.citation_type === "mail_in"
      ? row?.citation_mail_in_number?.replace("MAIL_IN_", " ") : val?.citation_number}`,
    },
    {
      field: "lotdata",
      headerName: "Lot",
      width: 150,
      valueFormatter: (val) => (val?.address),
    },
    {
      field: "tireTagSerialNumber",
      headerName: "Tag Serial Number",
      width: 150,
    },
    {
      field: "vehicle",
      headerName: "Vehicle Plate",
      width: 150,
      renderCell: (params) => (
        params.value?.correctedLP ? (
          <Tooltip
            title={
              <Box
                sx={{
                  backgroundColor: 'green',
                  padding: '0.5rem',
                  color: 'white',
                  borderRadius: '0.25rem',
                }}
              >
                {params.value.correctedLP}
              </Box>
            }
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'green',
                },
              },
            }}
          >
            <Box
              component="span"
              sx={{
                color: 'red',
                fontWeight: 'bold',
                cursor: 'pointer'
              }}
            >
              {params.value.licensePlate}
            </Box>
          </Tooltip>
        ) : (
          <Box
            component="span"
            sx={{
              color: 'inherit',
              fontWeight: 'normal',
            }}
          >
            {params.value?.licensePlate}
          </Box>
        )
      ),
    },
    { field: "citation_type", headerName: "Tag Type", width: 150},
    {
      field: "payment_status",
      headerName: "Status",
      width: 150,
      renderCell: (row) => 
      { var val = row?.row;
      return  <td align="center">
                          <span className={status_classes[val?.payment_status] || 'red'}>
                            {capitalize(val?.payment_status)}
                          </span>
                        </td>
      },
    },
    {
      field: "payment_date",
      headerName: "Payment Date",
      width: 150,
      valueFormatter: (val) => capitalize(val)
    },
    {
      field: "taggr break_down_amount",
      headerName: "Taggr amount",
      width: 150,
      valueFormatter: (val, row) =>  `${((row?.break_down_amount?.taggr?.amount ?? 0))}`

    },
    {
      field: "payout_transfer_group",
      headerName: "Payout transfer Group",
      width: 150,
      valueGetter: (val, row) =>  row?.holdTaggrFee ? "" : val
    },
    {
      field: "taggr_mail_in_payout_transfer_group",
      headerName: "Taggr Payout transfer Group",
      width: 150,
      valueGetter: (val, row) =>  row?.holdTaggrFee ? "Withhold" : val
    },
    {
      field: "operator break_down_amount",
      headerName: "operator amount",
      width: 150,
      valueFormatter: (val, row) =>  `${((row?.break_down_amount?.operator?.amount ?? 0))}`

    },
    {
      field: "TireTag amount break_down_amount",
      headerName: "TireTag amount",
      width: 150,
      valueFormatter: (val, row) =>  `${((row?.break_down_amount?.tire_tag?.amount ?? 0))}`

    },
    {
      field: "Taggr Solutions Amount break_down_amount",
      headerName: "Taggr Solutions Amount",
      width: 150,
      valueFormatter: (val, row) =>  `${((row?.break_down_amount?.taggr_solutions?.amount ?? 0))}`

    },
    {
        field: "break_down_amount",
        headerName: "Amount",
        width: 150,
        valueFormatter: (val) =>  `${((val?.calculative_amount ?? 0) + (val?.stripe_fees_offence ?? 0) + (val?.dock_fees ?? 0)).toFixed(2)}`

    },
    { field: "taggr", headerName: "Issued By", width: 150, valueFormatter: (val) => val?.fullname ?? "-" },
    { field: "", headerName: "Issued", width: 150, valueFormatter: (val, row) => {
        var date = row?.tiretag_citation_data?.created_at
        var mail_in_citation_date = row?.createdAt
        return moment(date ? date :mail_in_citation_date)?.tz(tz).format('YYYY-MM-DD HH:mm:ss')}
     },
  ];

  const initialPageState = {
    total: 0,
    defaultCurrentPage: 1,
    currentPage: 1,
    pageSize: 10,
    showModal: false,
    refund_citation_id: null,
    refund_popup_title: '',
    operator_name : '',
    amount: 0,
    citation_type: null
  }
  const status_classes ={
    paid: "paid-green",
    pending: "red",
    voided: "voided-red"
  }

  const [pageState, setPageState] = useReducer((prev, next) => {
    if (next?.currentPage) {
      allCitationsinfo(next?.currentPage)
    }
    return { ...prev, ...next }
  }, initialPageState)

  console.log(pageState,"pageState---------->>>>");

  const [searchKey, setSearchKey] = useState('');
  const searchInputRef = useRef()

  const [data, setdata] = useState();
  const [csvData, setCsvData] = useState();

  // const [Operatorname, setOperatorname] = useState();
  // console.log(pageState?.operatorName,"-----555555------>>>>");

  async function allCitationsinfo(page) {
    setShowPagination(true);
    const startDate = new Date(searchParams.get('start_date')) || new Date();
    const endDate = new Date(searchParams.get('end_date')) || new Date();
    const role = searchParams.get('role') || '';
    const payoutId = searchParams.get('payoutId') || '';
    const limit = searchParams.get('limit') || 10;
    dispatch(setLoading(true))
    try {
      const { value } = await props?.citation_reconcilation(payoutId, page, 10, { startDate, endDate: endDate });
      if (value?.success) {
        setdata(value)
        console.log(value, "000000000000");
        setPageState({ total: value?.total })
      } else {
        dispatch(setAlert("error", value?.message))
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message))
    }
    fetchName()
    // dispatch(setLoading(false))
  }



  async function handleDateSubmit() {
    // if (startDate && !endDate) {
    //   dispatch(setAlert('error', "Please select end date"))
    //   console.log("Please select start and end date");
    //   return;
    // }
    dispatch(setLoading(true))
    try {
      const payoutId = searchParams.get('payoutId') || '';
      const { value } = await props?.citation_reconcilation(payoutId, 0, 0, { startDate, endDate: endDate });
      setCsvData(value)
    } catch (error) {
      dispatch(setAlert("error", error?.message))
    }
    setTimeout(() => {
        dispatch(setLoading(false))
      }, 3000);
  }

  useEffect(() => {
    if (csvData?.citation?.length > 0 && csvLink.current) {
      csvLink.current.link.click();
    }
  }, [csvData]);

  const handleModalClose = () => {
    setReason('');
    setrefund_role(undefined);
    setTaggrFee(false);
    setPageState({ showModal: false });
  };


  const [refund_role, setrefund_role] = useState();
  const [taggrFee, setTaggrFee] = useState(false);


  console.log(refund_role,"refund_role------->>>>");
  // console.log(taggrFee,"taggrFee------->>>>");

  const onDeleteModalSubmit = async () => {

    // console.log(taggrFee,"000000000000000000");

    dispatch(setLoading(true))
    let err
    if (!reason || reason?.trim()?.length <= 0) {
      err = true
      dispatch(setAlert('error', "Reason can't be empty!"))
    }

    if (refund_role === undefined) {
      err = true
      dispatch(setAlert('error', "Please select Issued by!"))
    }

    if (!err) {
      setPageState({ showModal: false })

      try {
        const { value } = await props.refund_citation(pageState?.refund_citation_id, reason,refund_role,taggrFee);
        if (value?.success) {
          allCitationsinfo();
          dispatch(setAlert("success", value?.message))
        } else {
          dispatch(setAlert("error", value?.message))
        }
      } catch (error) {
        dispatch(setAlert("error", error?.message))
      }
      setReason('')
    }
    dispatch(setLoading(false))
  };

  const on_void_citation_modal_submit = async (payment_source_id) => {
    dispatch(setLoading(true))
    let err
    if (!reason || reason?.trim()?.length <= 0) {
      err = true
      dispatch(setAlert('error', "Reason can't be empty!"))
    }
    if (!err) {
      setPageState({ show_void_modal: false })

      try {
        const { value } = await props.void_citation(pageState?.void_citation_id, reason, taggrFee, payment_source_id);
        if (value?.success) {
          allCitationsinfo(1);
          dispatch(setAlert("success", value?.message))
        } else {
          dispatch(setAlert("error", value?.message))
        }
      } catch (error) {
        dispatch(setAlert("erro", error?.message))
      }
      setReason('')
      setTaggrFee(false)
    }
    dispatch(setLoading(false))
  };


  const search = async query => {
    try {
      if (query?.length > 2) {
        const { value } = await props?.searchallCitations('citation_admin', query)
        if (value?.success) {
          if(value?.results?.length < 9){ setShowPagination(false);}
          setdata({ citation: value?.results })
        }
        else {
          dispatch(setAlert('error', value?.message))
        }
      }
    } catch (error) {
      dispatch(setAlert('error', error?.message))
    }
  }

  // const navigateToPaymentPage = () => {
  //   window.open('http://localhost:3002/citations/payment/callcenter', '_blank');
  // }

  const toggleTextModal = () => {
    setShowTextModal(!showTextModal);
  }

  const handleTextSend = async () =>{
    let err
    if (!phoneNumber) {
      err = true
      dispatch(setAlert('error', "Phone number cant be empty"))
    }
    dispatch(setLoading(true))
    if (!err) {
      const phoneNumberForLink = encodeURIComponent(phoneNumber);
      try {
        const { value } = await props.send_sms(phoneNumber, `${process.env.REACT_APP_CHATBOT_LINK}?phone_number=${phoneNumberForLink}`);
        if (value?.success) {
          dispatch(setAlert("success", value?.message))
        } else {
          dispatch(setAlert("error", value?.message))
        }
      } catch (error) {
        dispatch(setAlert("error", error?.message))
      }
    }
    setShowTextModal(!showTextModal);
    dispatch(setLoading(false))
}

  return (
    <>
      <section className="adminCitations">
        <Header />
        <div className="citationsSection paddzero" id="profile-wrapper">
          <div className="title-wrapper">
            <div className="d-flex align-items-center">
              {
                role !== "callcenter" ?
                  <img src={leftArrow} className="left-arrow " onClick={() => navigate(-1)} />
                  : ""
              }
              <h4>Tags Associated with Payout</h4>
              </div>
              <div className="admin-citation-page">
              <div className="" style={{marginLeft: "5%"}}>
                {/* <div className="d-flex align-items-center border-block">
                    <div className="date-picker-list">
                        <label>Start Date</label>
                        <DatePicker
                            selected={startDate}
                            // onChange={(date) => setStartDate(date)}
                            onChange={(date) =>
                                setStartDate(moment(date).tz(tz).hour(6).minute(0).toDate())
                            }
                            placeholderText="--/--/----"
                            className="date-picker"
                            maxDate={new Date()}
                        />
                    </div>
                    <div className="date-picker-list">
                        <label>End Date</label>
                        <DatePicker
                            selected={endDate}                                             
                            // onChange={(date) =>
                            //     setendDate(moment(date).tz(tz).toDate())
                            // }
                            onChange={(date) => {
                                const newDate = moment(date).tz(tz);
                                newDate.set({ hour: 23, minute: 59, second: 59 });
                                setEndDate(newDate.toDate());
                            }}                                                
                            placeholderText="--/--/----"
                            className="date-picker"
                            minDate={startDate}
                            maxDate={new Date()}
                            disabled={!startDate}
                        />
                    </div>
                </div>
                <div className="operator-btns">
                  <div className="border-block-btn">
                    <button className="btn DarkBtn" onClick={handleDateSubmit} >Submit</button>
                  </div>
                </div> */}
                <CSVLink
                data={[
                  noticeFields, // header row
                  ...(csvData?.citation?.map((p) => [
                      p?.citation_type,
                      p?.citation_type !== "mail_in" ? p?.tiretag_citation_data?.citation_number : p?.parkpliant_reference_id,
                      p?.lotdata?.address,
                      p?.vehicle?.licensePlate,
                      p?.state,
                      p?.vehicle?.make,
                      p?.payment_status,
                      p?.break_down_amount?.calculative_amount,
                      p?.payment_date,
                      p?.taggr?.fullname,
                      p?.createdAt,
                      p?.break_down_amount?.taggr?.amount,
                      p?.break_down_amount?.operator?.amount,
                      p?.break_down_amount?.tire_tag?.amount,
                      p?.break_down_amount?.taggr_solutions?.amount,
                      p?.break_down_amount?.dock_fees,
                      p?.break_down_amount?.total_stripe_fees,
                      p?.break_down_amount?.service_fee,
                      p?.holdTaggrFee ? "" : p?.taggr_mail_in_payout_transfer_group,
                      p?.holdTaggrFee ? "Withhold" : p?.payout_transfer_group
                    ]
                  ) || []) // map data to rows
                ]}
                filename={"TagsPayouts.csv"}
                ref={csvLink}
                className=""
                style={{height: "fit-content"}}
              >
              </CSVLink>
              </div>   
              </div>
              <div className="flex">
              <button
                className="btn DarkBtn report-btn m-0"
                onClick={handleDateSubmit}
              >
                <Download />
                Download CSV
              </button>
            {/* <div className="search-input">
              <button>
                <img src={searchicon} />
              </button>
              {/* <input type="search" placeholder="Search..." /> */}
              {/* <input
                type="search"
                placeholder="Search..."
                ref={searchInputRef}
                onChange={async (e) => {
                  setSearchKey(e?.target?.value)
                  e?.target?.value?.length > 2 && debounce(await search(e?.target?.value), 2000)
                  e?.target?.value?.length === 0 && allCitationsinfo(1)
                }}
              /> */}
            {/* </div> */}
            </div>
          </div>
          <div className="cirtation-wrapper">
            <div className="table-responsive">
             {data?.citation?.length > 0 && <DataGrid
              rows={data?.citation?.map((citation, index) => (citation)
              )}
              columns={columns}
              pageSize={pageState?.pageSize ?? 10}
              rowsPerPageOptions={[10]}
              // hideFooterPagination
              // hideFooterSelectedRowCount
              hideFooter
              pagination
              onPageChange={(params) => setPageState({ currentPage: params.page })}
              rowCount={pageState.total}
              
              paginationMode="server"
              loading={props.loading}
              getRowId={(row) => row._id}
            />}
              <table className="table offence-table StripTable">
                <thead>
                  <tr>
                    {/* <th>Notice Number</th> */}
                    {/* <th>Lot</th> */}
                    {/* <th>Tag Serial Number</th> */}
                    {/* <th>Vehicle</th> */}
                    {/* <th>Vehicle Plate</th> */}
                    {/* <th>Status</th> */}
                    {/* <th>Amount</th> */}
                    {/* <th>Transaction Id</th> */}
                    {/* <th>Issued By</th> */}
                    {/* <th>Issued</th> */}
                    {/* <th>Actions</th> */}
                  </tr>
                </thead>
                <tbody>
                  {
                    data?.citation?.length > 0 ? ""
                    // data?.citation?.length > 0 ? (

                    //   data?.citation?.map((val) => {
                    //     console.log(val?.lotdata?.operator_fullname,"val_val");
                    //     const date = val?.tiretag_citation_data?.created_at
                    //     const mail_in_citation_date = val?.createdAt
                    //     const formattedDateTime = moment(date ? date :mail_in_citation_date)?.tz(tz).format('YYYY-MM-DD HH:mm:ss');
                    //     return (
                    //       <tr>
                    //         {/* <td>{val?.tiretag_citation_data?.citation_number}</td> */}
                    //         <td align="center">
                    //           {val?.citation_type === "mail_in"
                    //             ? val?.citation_mail_in_number
                    //             : val?.tiretag_citation_data?.citation_number}
                    //         </td>
                    //         <td align="center" className="lotText">
                    //           {val?.lotdata?.address}
                    //         </td>
                    //         <td align="center">{val?.tireTagSerialNumber}</td>
                    //         <td align="center">{val?.vehicle?.licensePlate}</td>
                    //         <td align="center">
                    //           <span
                    //             className={
                    //               val?.payment_status === "paid"
                    //                 ? "green LabelStatus"
                    //                 : "red LabelStatus"
                    //             }
                    //           >
                    //             {capitalize(val?.payment_status)}
                    //           </span>
                    //         </td>

                    //         <td align="center">
                    //           $
                    //           {(
                    //             val?.break_down_amount?.calculative_amount +
                    //             val?.break_down_amount?.stripe_fees_offence +
                    //             (val?.break_down_amount?.dock_fees ? val?.break_down_amount?.dock_fees : 0)
                    //           )?.toFixed(2)}
                    //         </td>
                    //         <td>{val?.txnId ?? "-"}</td>
                    //         <td align="center">{val?.taggr?.fullname ?? "-"}</td>
                    //         <td align="center">{formattedDateTime}</td>
                    //         <td>
                    //           {/* <a href="/citationsInformation"> */}
                    //           <div className="d-flex align-items-center">
                    //             <Link
                    //               to={`/citationsInformation/${val?._id}`}
                    //               className="eyeBtn"
                    //             >
                    //               <img src={DarkView} className="DarkImg" />
                    //               <img src={WhiteView} className="WhiteImg" />
                    //             </Link>

                    //             {/* <button  className={val?.payment_status === "voided" ? "DarkEditBtnDisbled" : "DarkEditBtn"}  */}
                    //             <button
                    //               className="DarkEditBtnDisbled"
                    //               onClick={() => {
                    //                                                     if (val?.payment_status === "voided") {
                    //                   dispatch(
                    //                     setAlert(
                    //                       "info",
                    //                       "Notice already voided."
                    //                     )
                    //                   );
                    //                 } else if (
                    //                   val?.payment_status !== "paid" &&
                    //                   val?.payment_status !== "refunded"
                    //                 ) {
                    //                   setPageState({
                    //                     void_citation_id: val?._id,
                    //                     citation_type: val?.citation_type,
                    //                     show_void_modal: true,
                    //                     citation_type: val?.citation_type,
                    //                     void_popup_title:
                    //                       val?.tiretag_citation_data
                    //                         ?.citation_number,
                    //                     amount: (
                    //                       val?.break_down_amount
                    //                         ?.calculative_amount +
                    //                       val?.break_down_amount
                    //                         ?.stripe_fees_offence
                    //                     )?.toFixed(2),
                    //                   });
                    //                 } else {
                    //                   dispatch(
                    //                     setAlert(
                    //                       "info",
                    //                       "You can't void a Notice after it's paid!"
                    //                     )
                    //                   );
                    //                 }
                    //               }}
                    //               disabled={(val?.payment_status !== "pending")}
                    //             >
                    //               {val?.payment_status === "voided"
                    //                 ? "Voided"
                    //                 : "Void"}
                    //             </button>

                    //             {val?.payment_status === "paid" ||
                    //             val?.payment_status === "voided" ? (
                    //               <button
                    //                 disabled={
                    //                   val?.payment_status === "paid" ||
                    //                   val?.payment_status === "voided"
                    //                 }
                    //                 className="DarkEditBtnDisbled"
                    //               >
                    //                 Edit
                    //               </button>
                    //             ) : (
                    //               <Link
                    //                 to={`/Edit_citationsInformation/${val?._id}`}
                    //                 className="DarkBlackBtn"
                    //               >
                    //                 Edit
                    //               </Link>
                    //             )}
                    //             {val?.payment_status === "paid" ||
                    //             val?.payment_status === "voided" ? (
                    //               <button
                    //                 disabled={
                    //                   val?.payment_status === "paid" ||
                    //                   val?.payment_status === "voided"
                    //                 }
                    //                 className="DarkEditBtnDisbled"
                    //               >
                    //                 Pay
                    //               </button>
                    //             ) : (
                    //               <a
                    //                 className="DarkBlackBtn"
                    //                 href={`${
                    //                   process.env.REACT_APP_HOST ===
                    //                   "DEVELOPMENT"
                    //                     ? "http://localhost:3002"
                    //                     : "https://paytaggr.com"
                    //                 }?serialNumber=${
                    //                   val?.tireTagSerialNumber
                    //                 }&licensePlate=${
                    //                   val?.vehicle?.licensePlate
                    //                 }&payment_by=${
                    //                   role === "superAdmin"
                    //                     ? "admin"
                    //                     : role === "callcenter"
                    //                     ? "call_center"
                    //                     : ""
                    //                 }`}
                    //               >
                    //                 Pay
                    //               </a>
                    //             )}

                    //             {/* <button className="DarkGreenBtn">
                    //                 Pay
                    //               </button> */}

                    //             {val?.payment_status === "pending" ? (
                    //               !val.proof ? <button onClick={toggleTextModal}>
                    //                 Text
                    //               </button>
                    //               :
                    //               <button
                    //                 className="proof-button"
                    //                 onClick={() => {
                    //                   window.location.href = `/citationsInformation/${val?._id}?is_proof=true`;
                    //                 }}
                    //               >
                    //                 Proof
                    //               </button>
                    //             ) : (val?.payment_status === "pending" ||
                    //             val?.payment_status === "voided"||
                    //             val?.payment_status === "refunded") && role !== "callcenter" ? (
                    //               <button
                    //                 disabled={
                    //                   val?.payment_status === "pending" ||
                    //                   val?.payment_status === "voided"||
                    //                   val?.payment_status === "refunded"
                    //                 }
                    //                 className="RefundBtnDisbled"
                    //                 onClick={() =>
                    //                   {setPageState({
                    //                     refund_citation_id: val?._id,
                    //                     operator_name : val?.lotdata?.operator_fullname,
                    //                     refund_popup_title : val?.tiretag_citation_data?.citation_number,
                    //                     showModal: true,
                    //                   })
                    //                 console.log(val?._id);
                    //                 }
                    //                 }
                    //               >
                    //                 Refund
                    //               </button>
                    //             ) : role !== "callcenter" && (
                    //               <button
                    //                 className="DarkBlackBtn"
                    //                 onClick={() =>
                    //                   setPageState({
                    //                     refund_citation_id: val?._id,
                    //                     operator_name : val?.lotdata?.operator_fullname,
                    //                     refund_popup_title : val?.tiretag_citation_data?.citation_number,
                    //                     showModal: true,
                    //                      amount: (
                    //                       val?.break_down_amount
                    //                         ?.calculative_amount +
                    //                       val?.break_down_amount
                    //                         ?.stripe_fees_offence
                    //                     )?.toFixed(2)
                    //                   })
                    //                 }
                    //               >
                    //                 Refund
                    //               </button>
                    //             )}
                    //             {/* <button className="DarkBlackBtn" onClick={() => setPageState({
                    //                     refund_citation_id: val?._id,
                    //                     showModal: true
                    //                   })}>
                    //                 Refund
                    //               </button> */}
                    //           </div>
                    //         </td>
                    //       </tr>
                    //     );

                    //   })

                    // )
                      :
                      (
                        data?.citation === null || data?.citation?.length === 0 ?
                          searchKey &&
                          <div className="dataText">
                            <h5>No Result Found.</h5>
                          </div>
                          :
                          <div className="dataText">
                            <h5></h5>
                          </div>
                      )

                  }
                </tbody>
              </table>

              {data?.citation?.length > 0 && showPagination && (
                // console.log("ooooo"),
                <div className='pagination-block'>
                  <Pagination
                    hideOnSinglePage
                    defaultCurrent={pageState?.defaultCurrentPage}
                    onChange={page => setPageState({ currentPage: page })}
                    current={pageState?.currentPage}
                    pageSize={pageState?.pageSize}
                    total={pageState?.total}
                    showSizeChanger={false}
                  />
                </div>
              )}


              {/* <div className='pagination-block'>
                <Pagination
                  hideOnSinglePage
                  defaultCurrent={pageState?.defaultCurrentPage}
                  onChange={page => setPageState({ currentPage: page })}
                  current={pageState?.currentPage}
                  pageSize={pageState?.pageSize}
                  total={pageState?.total}
                  showSizeChanger={false}
                />
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <Refund_modal
        show={pageState?.showModal}
        // onClose={() => setPageState({ showModal: false })}
        onClose={handleModalClose}
        setReason={(e) => setReason(e?.target?.value)}
        amount={pageState?.amount}
        reason={reason}
        setrefund_role={setrefund_role}
        refund_role={refund_role}
        setTaggrFee={setTaggrFee}
        taggrFee={taggrFee}
        onSubmit={onDeleteModalSubmit}
        title={pageState?.refund_popup_title}
        header_title="Refund"
        operator_name = {pageState?.operator_name}
      />
      <Refund_modal
        show={pageState?.show_void_modal}
        onClose={() => {
          setPageState({ show_void_modal: false })
          setReason('')
          setTaggrFee(false)
        }}
        setReason={(e) => setReason(e?.target?.value)}
        onSubmit={on_void_citation_modal_submit}
        amount={pageState?.amount}
        reason={reason}
        title={pageState?.void_popup_title}
        header_title="Void"
        citation_type={pageState?.citation_type}
        setTaggrFee={setTaggrFee}
        taggrFee={taggrFee}
      />

      <TextModal title="Send Dispute Form" show={showTextModal} onClose={toggleTextModal} onSubmit={handleTextSend} phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />
    </>
  );
}
