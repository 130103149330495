import React, { useEffect, useReducer, useRef, useState } from "react";
import { Link } from "react-router-dom";
import searchicon from "../../images/search.svg";
import HeaderContainer from "../../components/common/Header/container";
import Header from "../../components/common/Header/container";
import View from "../../images/IC-view.svg";
import DarkView from "../../images/DarkViewImg.svg";
import WhiteView from "../../images/WhiteViewImg.svg";
import { setAlert, setLoading } from "../../store/actioncreator";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import { capitalize, debounce } from "../../global";
import { Pagination } from "antd";
import Refund_modal from "../../utils/refund_modal";
import { async } from "rxjs";

export default function OperatorMailInCitationComponent(props) {

  const dispatch = useDispatch()
  const [data, setdata] = useState();
  const tz = moment.tz?.guess()

  const initialPageState = {
    total: 0,
    defaultCurrentPage: 1,
    currentPage: 1,
    pageSize: 10,
    citation_type: null
  }

  const [pageState, setPageState] = useReducer((prev, next) => {
    if (next?.currentPage) {
      Operator_citations(next?.currentPage)
    }
    return { ...prev, ...next }
  }, initialPageState)


  const [searchKey, setSearchKey] = useState('');
  const searchInputRef = useRef()

  useEffect(() => {
    Operator_citations(1);
  }, []);

   async function Operator_citations(page) {
    try {
      const { value } = await props.operatoCitation({ page, size: 10, type: "mail_in" })
      if (value?.success) {
        setdata(value)
        setPageState({ total: value?.total })
      } else {
        dispatch(setAlert("error", value?.message))
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message))
    }
  }


  async function search_operator_citation(query) {
    try {
      if (query?.length > 2) {
        const { value } = await props?.searchForOperator('citation_operator', query, "mail_in")
        if (value?.success) {
          setdata({ citation: value?.results })
          setPageState({ total: 0 })
        }
        else {
          dispatch(setAlert('error', value?.message))
        }
      }
    } catch (error) {
      dispatch(setAlert('error', error?.message))
    }
  }

  const [refund_role, setrefund_role] = useState("operator");
  const [taggrFee, setTaggrFee] = useState(false);
  const [reason, setReason] = useState('')

  const on_void_citation_modal_submit = async (payment_source_id) => {
    dispatch(setLoading(true))
    let err
    if (!reason || reason?.trim()?.length <= 0) {
      err = true
      dispatch(setAlert('error', "Reason can't be empty!"))
    }
    if (!err) {
      setPageState({ show_void_modal: false })

      try {
        const { value } = await props.void_citation(pageState?.void_citation_id, reason, taggrFee, payment_source_id);
        if (value?.success) {
          Operator_citations(1);
          dispatch(setAlert("success", value?.message))
        } else {
          dispatch(setAlert("error", value?.message))
        }
      } catch (error) {
        dispatch(setAlert("error", error?.message))
      }
      setReason('')
      setTaggrFee(false)
    }
    dispatch(setLoading(false))
  };


  const onDeleteModalSubmit = async () => {

    let err
    if (!reason || reason?.trim()?.length <= 0) {
      err = true
      dispatch(setAlert('error', "Reason can't be empty!"))
    }

    if (!err) {
      setPageState({ showModal: false })

      try {
        const { value } = await props.refund_citation(pageState?.refund_citation_id, reason,refund_role,taggrFee);
        if (value?.success) {
          // allCitationsinfo(1);
          dispatch(setAlert("success", value?.message))
        } else {
          dispatch(setAlert("error", value?.message))
        }
      } catch (error) {
        dispatch(setAlert("error", error?.message))
      }
      setReason('')
    }
  };




  return (
    <>
      <section className="adminCitations">
        <Header />
        <div className="citationsSection paddzero" id="profile-wrapper">
          <div className="title-wrapper">
            <div className="d-flex align-items-center">
              {/* <img src={leftArrow} className="left-arrow " onClick={() => navigate(-1)} /> */}
              <h4> Mail In Tags Deployed</h4>
            </div>
            <div className="search-input">
              <button>
                <img src={searchicon} />
              </button>
              {/* <input type="search" placeholder="Search..." /> */}
              <input
                type="search"
                ref={searchInputRef}
                placeholder="Search..."
                onChange={async (e) => {
                  setSearchKey(e?.target?.value)
                  e?.target?.value?.length > 2 && debounce(await search_operator_citation(e?.target?.value), 2000)
                  e?.target?.value?.length === 0 && Operator_citations(1);
                }}
              />

            </div>
          </div>
          <div className="cirtation-wrapper">
            <div className="table-responsive">
              <table className="StripTable">
                <thead>
                  <tr>
                    <th>Notice Number</th>
                    <th>Lot</th>
                    <th>Tag Serial Number</th>
                    <th>Vehicle Plate</th>
                    <th>Status</th>
                    <th>Issued</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.citation?.length > 0 ? (
                    data?.citation?.map((val) => {
                      const date = val?.tiretag_citation_data?.created_at
                      const mail_in_citation_date = val?.createdAt
                      const formattedDateTime = moment(date ? date : mail_in_citation_date)?.tz(tz).format('YYYY-MM-DD HH:mm:ss');
                      return (
                        <tr>
                          {/* <td>{val?.tiretag_citation_data?.citation_number}</td> */}
                          <td align="center">{val?.citation_type === "mail_in" ? val?.citation_mail_in_number : val?.tiretag_citation_data?.citation_number}</td>
                          <td align="center ">{val?.lotdata?.address}</td>
                          <td align="center ">
                            {val?.tireTagSerialNumber}
                          </td>
                          <td align="center">{val?.vehicle?.licensePlate}</td>
                          <td align="center">
                            <span className={val?.payment_status === "paid" ? "green" : "red"}>
                              {capitalize(val?.payment_status)}
                            </span>
                          </td>
                          <td align="center">{formattedDateTime}</td>
                          {/* <td>
                            <Link to={`/citations/detail/${val?._id}`} className="eyeBtn">
                              <img src={DarkView} className="DarkImg" />
                              <img src={WhiteView} className="WhiteImg" />
                            </Link>
                          </td> */}
                            <td>
                             <div className="d-flex align-items-center">
                            <Link to={`/citations/detail/${val?._id}`} className="eyeBtn">
                              <img src={DarkView} className="DarkImg" />
                              <img src={WhiteView} className="WhiteImg" />
                            </Link>
                            {val?.payment_status === "paid" ||
                                val?.payment_status === "voided" ? (
                                  <button
                                    disabled={
                                      val?.payment_status === "paid" ||
                                      val?.payment_status === "voided"
                                    }
                                    className="DarkEditBtnDisbled"
                                  >
                                    Edit
                                  </button>
                                ) : (
                                  <Link
                                    to={`/Edit_citationsInformation/${val?._id}`}
                                    className="DarkBlackBtn"
                                  >
                                    Edit
                                  </Link>
                                )}

                            <button
                              className="DarkEditBtnDisbled DarkBlackBtn"
                              onClick={() => {
                                if (val?.payment_status === "voided") {
                                  dispatch(
                                    setAlert(
                                      "info",
                                      "Notice already voided."
                                    )
                                  );
                                } else if (
                                  val?.payment_status !== "paid" &&
                                  val?.payment_status !== "refunded"
                                ) {
                                  setPageState({
                                    void_citation_id: val?._id,
                                    show_void_modal: true,
                                    citation_type: val?.citation_type,
                                    void_popup_title: val?.citation_type === "tag_vehicle"
                                    ? val?.tiretag_citation_data?.citation_number
                                    : val?.citation_mail_in_number?.replace("MAIL_IN_", " "),
                                    amount: (
                                      val?.break_down_amount
                                        ?.calculative_amount +
                                      val?.break_down_amount
                                        ?.stripe_fees_offence
                                    )?.toFixed(2),
                                  });
                                } else {
                                  dispatch(
                                    setAlert(
                                      "info",
                                      "You can't void a Notice after it's paid!"
                                    )
                                  );
                                }
                              }}
                              disabled={(val?.payment_status !== "pending")}
                            >
                              {val?.payment_status === "voided"
                                ? "Voided"
                                : "Void"}
                            </button>
                              
                             {  val?.payment_status === "refunded" ?

                              <button className="RefundBtnDisbled"
                              disabled={                                
                                val?.payment_status === "refunded"
                              }
                              >                          
                                    Refund
                                  </button>
                                  : 
                                  <button className="DarkBlackBtn" 
                             onClick={() => setPageState({
                                        refund_citation_id: val?._id,
                                        showModal: true,
                                        refund_popup_title:val?.tiretag_citation_data?.citation_number,
                                        amount : val?.amount
                                      })}
                                      >
                                    Refund
                                  </button>
                                  
                                }
                                  </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : data?.citation === null ||
                    data?.citation?.length === 0 ? (
                    searchKey && (
                      <div className="dataText">
                        <h5>No Result Found</h5>
                      </div>
                    )
                  ) : (
                    <div className="dataText">
                      <h5></h5>
                    </div>
                  )
                  }
                </tbody>
              </table>
              <div className="pagination-block">
              <Pagination
                hideOnSinglePage
                defaultCurrent={pageState?.defaultCurrentPage}
                // onChange={(page) => setPageState({ currentPage: page })}
                onChange={(page) => {
                  console.log("Current Page:", page);
                  setPageState({ currentPage: page });
                }}
                current={pageState?.currentPage}
                pageSize={pageState?.pageSize}
                total={pageState?.total}
                showSizeChanger={false}
              />
            </div>
          </div>
          </div>
        </div>
      </section>
      <Refund_modal
        show={pageState?.showModal}
        onClose={() => setPageState({ showModal: false })}
        role={"operator"}
        setReason={(e) => setReason(e?.target?.value)}
        amount={pageState?.amount}
        reason={reason}
        onSubmit={onDeleteModalSubmit}
        title={pageState?.refund_popup_title}
        header_title="Refund"
      />
      <Refund_modal
        show={pageState?.show_void_modal}
        onClose={() => {
          setPageState({ show_void_modal: false })
          setReason('')
          setTaggrFee(false)
        }}
        setReason={(e) => setReason(e?.target?.value)}
        onSubmit={on_void_citation_modal_submit}
        amount={pageState?.amount}
        reason={reason}
        title={pageState?.void_popup_title}
        header_title="Void"
        citation_type={pageState?.citation_type}
        setTaggrFee={setTaggrFee}
        taggrFee={taggrFee}
      />
    </>
  );
}
