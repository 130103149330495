import { Pagination } from "antd";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../../components/common/Header/container";
import { capitalize, debounce } from "../../global";
import DarkView from "../../images/DarkViewImg.svg";
import searchicon from "../../images/search.svg";
import WhiteView from "../../images/WhiteViewImg.svg";
import { setAlert } from "../../store/actioncreator";
import moment from "moment-timezone";

const initialPageState = {
  total: 0,
  defaultCurrentPage: 1,
  currentPage: 1,
  pageSize: 10,
};

export default function OperatorDisputedCitations(props) {
  const dispatch = useDispatch();

  const [searchKey, setSearchKey] = useState("");
  const searchInputRef = useRef();
  const [data, setdata] = useState();

  const operatorDisputedCitations = async (page) => {
    try {
      const { value } = await props.get_all_disputed_citations_operator({
        page,
        limit: 10,
      });
      if (value?.success) {
        setdata(value);
        setPageState({ total: value?.total });
      } else {
        dispatch(setAlert("error", value?.message));
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
  }

  const searchOperatorDisputedCitations = async (query) => {
    try {
      if (query?.length > 2) {
        const { value } =
          await props?.search_disputed_citations_operator(query);
        if (value?.success) {
          setdata(value);
        } else {
          dispatch(setAlert("error", value?.message));
        }
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
  }

  const [pageState, setPageState] = useReducer((prev, next) => {
    if (next?.currentPage) {
      operatorDisputedCitations(next?.currentPage);
    }
    return { ...prev, ...next };
  }, initialPageState);

  useEffect(() => {
    operatorDisputedCitations(1);
  }, []);

  return (
    <>
      <section className="adminCitations">
        <Header />
        <div className="citationsSection paddzero" id="profile-wrapper">
          <div className="title-wrapper">
            <div className="d-flex align-items-center">
              <h4>Disputed Citations</h4>
            </div>
            <div className="search-input">
              <button>
                <img src={searchicon} />
              </button>
              <input
                type="search"
                ref={searchInputRef}
                placeholder="Search..."
                onChange={async (e) => {
                  setSearchKey(e?.target?.value);
                  e?.target?.value?.length > 2 &&
                    debounce(
                      await searchOperatorDisputedCitations(e?.target?.value),
                      2000,
                    );
                  e?.target?.value?.length === 0 &&
                    operatorDisputedCitations(1);
                }}
              />
            </div>
          </div>
          <div className="cirtation-wrapper">
            <div className="table-responsive">
              <table className="StripTable">
                <thead>
                  <tr>
                    <th>Dispute Date</th>
                    <th>ID</th>
                    {/* <th>Notice Number</th> */}
                    <th>License Plate</th>
                    <th>Notice Issue Date</th>
                    <th>Lot Address</th>
                    <th>Chatbot Action</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data?.disputedCitations?.length > 0 ? (
                    data?.disputedCitations?.map((val) => {
                      return (
                        <tr>
                          <td align="center">{moment(val.createdAt).format("YYYY-MM-DD hh:mm A")}</td>
                          <td align="center">{val._id}</td>
                          {/* <td align="center">{val.notice_number || "Nil"}</td> */}
                          <td align="center">{val.license_plate || "Nil"}</td>
                          <td align="center">
                            {val.notice_issue_date || "Nil"}
                          </td>
                          <td align="center">
                            {val.citationId?.lotId?.address || "Nil"}
                          </td>
                          <td align="center">
                            {val?.status ? (
                              <span
                                className={
                                  val?.status === "reduce"
                                    ? "green LabelStatus"
                                    : "red LabelStatus"
                                }
                              >
                                {capitalize(val?.status)}
                              </span>
                            ) : (
                              <span className="yellow LabelStatus">
                                Incomplete
                              </span>
                            )}
                          </td>

                          <td align="center">
                            <div className="d-flex align-items-center">
                              <Link
                                to={`/operator/disputed-citations/${val?._id}`}
                                className="eyeBtn"
                              >
                                <img src={DarkView} className="DarkImg" />
                                <img src={WhiteView} className="WhiteImg" />
                              </Link>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : data?.disputedCitations === null ||
                    data?.disputedCitations.length === 0 ? (
                    searchKey && (
                      <div className="dataText">
                        <h5>No Result Found.</h5>
                      </div>
                    )
                  ) : (
                    <div className="dataText">
                      <h5></h5>
                    </div>
                  )}
                </tbody>
              </table>
              <div className="pagination-block">
                <Pagination
                  hideOnSinglePage
                  defaultCurrent={pageState?.defaultCurrentPage}
                  onChange={(page) => {
                    console.log("Current Page:", page);
                    setPageState({ currentPage: page });
                  }}
                  current={pageState?.currentPage}
                  pageSize={pageState?.pageSize}
                  total={pageState?.total}
                  showSizeChanger={false}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
