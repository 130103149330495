import { connect } from "react-redux";
import { lotprovider,user, deleteyourlot, addlots, getProviders, getLotsById , searchForOperator} from "../../store/home/duck";
import YourLotsComponent from "./component";

const YourLotsContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // //  comment for amplify
  // Map actions to dispatch and props
  {
    lotprovider,
    user,
    deleteyourlot,
    addlots,
    getProviders,
    getLotsById,
    searchForOperator
  }
)(YourLotsComponent);

export default YourLotsContainer;
