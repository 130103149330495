import { connect } from 'react-redux'
import { user } from '../../../store/home/duck'
import Header from './citation_header_component'

const CitationsHeaderContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
    
    user,
  }
)(Header)

export default CitationsHeaderContainer
