import React from "react";
import Modal from "react-modal";
import { useParams } from "react-router-dom";
import { Pagination } from 'antd';
import search from "../../../images/search.svg";
import location from "../../../../src/images/location.svg";
import citationImage from "../../../images/citation-image.png";
import image from "../../../images/image.svg";
import violation from "../../../images/violations.svg";
import Details from "../../../images/details.svg";
import leftArrow from "../../../images/left-arrow.svg";
import AdminHeader from "../Citations-Header/container";
import OperatorHeader from "../../../components/common/Header/container";
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { setAlert } from "../../../store/actioncreator";
import { useDispatch } from "react-redux";
import { Two_decimals, capitalize } from "../../../global";
export default function Edit_CitationsInformationComponent(props) {
    // const { id } = useParams()
    const params = useParams();
    const dispatch = useDispatch();

    const [data, setdata] = useState({})
    const [citation_id, setcitation_id] = useState();
    const [offence_id, setoffence_id] = useState();
    const [selectedOffenseId, setSelectedOffenseId] = useState('');
    const [user_role, set_user_role] = useState('superAdmin')

    useEffect(() => {
        fetchMe()
        callApi()
        hideMenu();
    }, [])

    const fetchMe = async () => {
        try {
          const { value } = await props.user();
          if (value?.success) {
              set_user_role(value?.user?.role);
          }
          else {
            dispatch(setAlert('error', value?.message))
          }
        } catch (error) {
          dispatch(setAlert('error', error?.message))
        }
      };

    const callApi = async () => {
        try {
            const id = params.id
            const { value } = await props.get_single_citation_details(id)
            if (value.success) {
                setdata(value?.citation)
                Get_violation(value?.citation?.lot?._id)
                // console.log(value,"0000000000000");
                setSelectedOffenseId(value?.citation?.offence?._id)
                setcitation_id(value?.citation?._id)
            } else {
                dispatch(setAlert("error", value?.message))
            }
        } catch (error) {
            dispatch(setAlert("error", error?.message))
        }
    }

    const [notices_to_select, setnotices_to_select] = useState()

    async function Get_violation(lotId) {
        try {
            const { value } = await props.getLotsById(lotId)
            if (value?.success) {
                // console.log(value,"1111111111111111");
                setnotices_to_select(value?.lot?.offences)
            } else {
                dispatch(setAlert("error", value?.message))
            }
        } catch (error) {
            dispatch(setAlert("error", error?.message))
        }
    }

    // console.log(citation_id);

  


    async function Edit_citation_violation(citation_id, selectedOffenseId) {
        // console.log("workkkkk");
        // console.log(citation_id,"ppppppppppp");
        try {
            const { value } = await props.update_citation_violation(citation_id, selectedOffenseId)
            if (value?.success) {
                console.log("success");
                // console.log(value,citation_id,"ppppppppppp");
                dispatch(setAlert("success", value?.message))
                navigate((user_role === "operator" || user_role === "manager") ? '/operatorcitations' : "/admincitations")
            } else {
                dispatch(setAlert("error", value?.message))
                // console.log(value?.message,"------00000000");
            }
        } catch (error) {
            dispatch(setAlert("error", error?.message))
            console.log(error?.message, "))))))00");
        }
    }

    const hideMenu = () => {
        var element = document.getElementById("profile-wrapper");
        var menu = document.getElementById("test_menu");
        element?.classList.add("left-wrapper");
        menu?.classList.remove("showmenu");
      }

    // console.log(citation_id,"idididididididid");
    // console.log(selectedOffenseId, "iiiiiiiiiiiiiiiiiiiiidddddddddddddd");

    
    const navigate = useNavigate();
    console.log(user_role, "<<<userrole")
    return (
        <>
            <section className="adminCitations">
               {(user_role === "operator" || user_role === "manager") ? <OperatorHeader /> : <AdminHeader />}
                <div className={`citationsSection paddzero ${(user_role !== "operator" && user_role !== "manager") ? 'left-wrapper' : ""}`} id="profile-wrapper">
                    <div className="title-wrapper">
                        <div className="d-flex align-items-center">
                            <img src={leftArrow} className="left-arrow" onClick={() => navigate(-1)} />
                            <h4>Notices</h4>
                        </div>
                    </div>
                    <div className="cirtation-wrapper">
                        <div className="details-block-wrapper">
                            <h6><img src={Details} />Notice Details</h6>
                            <div className="details-wrapper-block">
                                <div className="details-list">
                                    <label>Notice Number</label>
                                    <input
                                        value={data?.tiretag_citation_data?.citation_number ?? data?.parkpliant_reference_id ?? ""}
                                        type="text" id="text"
                                        className="form-control"
                                        disabled />
                                </div>
                                <div className="details-list">
                                    <label>Vehicle</label>
                                    <input
                                        value={data?.vehicle?.licensePlate ?? ""}
                                        type="text"
                                        id="text"
                                        className="form-control"
                                        disabled />
                                </div>
                                <div className="details-list">
                                    <label>Location</label>
                                    <input value={data?.lot?.address ?? ""} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>Issued by </label>
                                    <input value={data?.user?.fullname ?? ''} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>Tire Tag S. No.</label>
                                    <input value={data?.tireTagSerialNumber ?? ""} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>Release code</label>
                                    <input value={data?.tag_release_code} type="text" id="text" className="form-control" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="details-block-wrapper">
                            <h6><img src={violation} />Violation Fees and Details</h6>
                            <div className="details-wrapper-block">

                                {/* <div className="details-list">
                                    <label>Violation code(s)</label>
                                    <input value={data?.offence?.offenceName} type="text" id="text" className="form-control" disabled />
                                </div> */}

                                {/* <div className="details-list">
                                    <label>Violation code(s)</label>
                                    <select
                                        className="form-control"
                                        value={selectedOffenseId}
                                        onChange={(e) => {
                                            setSelectedOffenseId(e.target.value);
                                            console.log("Selected Offense ID:", e.target.value);
                                        }}
                                    >
                                        {notices_to_select &&
                                            notices_to_select.map((notice) => (
                                                <option key={notice?._id} value={notice?._id}>
                                                    {notice?.offenceName}
                                                </option>
                                            ))}
                                    </select>
                                </div> */}

                                <div className="details-list">
                                    <label>Violation code(s)</label>
                                    {notices_to_select && (
                                        <select
                                            className="form-control CustomSelect"
                                            value={selectedOffenseId || data?.offence?._id}
                                            onChange={(e) => {
                                                setSelectedOffenseId(e.target.value);
                                                console.log("Selected Offense ID:", e.target.value);
                                            }}
                                        >
                                            {notices_to_select.map((notice) => (
                                                <option key={notice?._id} value={notice?._id}>
                                                    {notice?.offenceName}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </div>





                                <div className="details-list">
                                    <label>Violation fee</label>
                                    <input value={data?.break_down_amount?.offence_fees > 60 ? data?.break_down_amount?.offence_fees + 35 : data?.break_down_amount?.offence_fees} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>Payment due</label>
                                    <input value={data?.paid ? 0 : data?.amount} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>Location</label>
                                    <input value={data?.lot?.address ?? ""} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>Holding fee</label>
                                    <input value={Two_decimals(data?.break_down_amount?.holding_fees - data?.break_down_amount?.stripe_fees_holding) ?? ""} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>Payment status</label>
                                    <input value={capitalize(data?.payment_status) ?? ''} type="text" id="text" className="form-control" disabled />
                                </div>
                            </div>
                        </div>


                        <div className="details-block-wrapper">
                            <h6><img src={violation} />Breakdown Amount</h6>
                            <div className="details-wrapper-block">

                                <div className="details-list">
                                    <label>Taggr amount</label>
                                    <input value={data?.break_down_amount?.taggr?.amount ?? 0} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>Operator amount</label>
                                    <input value={data?.break_down_amount?.operator?.amount ?? 0} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>Tire tag amount</label>
                                    <input value={data?.break_down_amount?.tire_tag?.amount ?? 0} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>Taggr solutions amount</label>
                                    <input value={data?.break_down_amount?.taggr_solutions?.amount ?? 0} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>Dock fees</label>
                                    <input value={data?.break_down_amount?.dock_fees ?? 0} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>Total stripe fees</label>
                                    <input value={data?.break_down_amount?.total_stripe_fees ?? 0} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>Call center fees</label>
                                    <input value={data?.break_down_amount?.service_fee ?? 0} type="text" id="text" className="form-control" disabled />
                                </div>
                            </div>
                        </div>



                        <div className="details-block-wrapper">
                            <h6><img src={Details} />Vehicle Details</h6>
                            <div className="details-wrapper-block">

                                <div className="details-list">
                                    <label>Vehicle plate</label>
                                    <input value={data?.vehicle?.licensePlate ?? ""} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>State</label>
                                    <input value={data?.state ?? ""} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>Make</label>
                                    <input value={data?.vehicle?.make ?? ""} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list ">
                                    <label>Model</label>
                                    <input value={data?.vehicle?.model ?? ""} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">

                                    <label>Color</label>
                                    <input value={data?.vehicle?.color ?? ""} type="text" id="text" className="form-control" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="images-wrapper">
                            <h6><img src={image} />Image</h6>
                            <div className="details-wrapper-block">

                                <div className="details-list">

                                    <div className="citations_image">
                                        <img src={data?.images?.licensePlateImage ?? data?.images?.license_plate_image} />
                                    </div>
                                </div>

                                <div className="details-list">

                                    <div className="citations_image">
                                        <img src={data?.images?.signageImage ?? data?.images?.dashboard_image} />
                                    </div>
                                </div>

                                <div className="details-list">

                                    <div className="citations_image">
                                        <img src={data?.images?.windowDecalImage} />
                                    </div>
                                </div>

                                <div className="details-list">

                                    <div className="citations_image">
                                        <img src={data?.images?.tireTagWheelImage} />
                                    </div>
                                </div>
                                {
                                    data?.images?.optionalImage?.length>0 &&
                                    data?.images?.optionalImage.map((item) => <div className="details-list">
                                        <div className="citations_image">
                                            <img src={item} />
                                        </div>
                                    </div>)
                                }
                            </div>
                        </div>

                        <div className="details-wrapper ">

                            <h6><img src={Details} />Remarks</h6>
                            <p>{data?.remark}</p>
                        </div>
                        <div className="text-center">
                            <button
                                type="button"
                                className="btn DarkBtn"
                                style={{ display: 'inline-flex', marginBottom: '30px', }}
                                onClick={() => Edit_citation_violation(citation_id, selectedOffenseId)}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
