import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Loading from "../../utils/loading/Loading";
import Header from "../../components/common/Header/container";
import leftArrow from "../../images/left-arrow.svg";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { setAlert } from "../../store/actioncreator";
import { useDispatch } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddManagerComponent(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [selectLots, setSelectLots] = useState([]);
  const [selectLotsIds, setSelectLotsIds] = useState([]);
  const [lots, setLots] = useState([]);
  const [managerName, setManagerName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errors, setErrors] = useState({});
  const managerId = new URLSearchParams(window.location.search)?.get(
    "managerId"
  );

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log(value, "<<value")
    setSelectLotsIds(value)
    setSelectLots(lots?.filter(lot => value?.indexOf(lot?._id) > -1 ? true : false));
    setErrors((prevErrors) => ({ ...prevErrors, selectLots: "" }));
  };

  useEffect(() => {
    getLotList();
    if (managerId) {
      fetchManagerData();
    }
  }, [managerId]);

  async function getLotList() {
    const { value } = await props.lotprovider({});
    if (value?.success) {
      setLots(
        value?.lots?.map((lot) => ({ _id: lot?._id, lotname: lot?.lotname }))
      );
    }
  }

  async function fetchManagerData() {
    const { value } = await props.getManagerById(managerId);
    if (value?.success) {
      const manager = value?.manager;
      setManagerName(manager?.fullname);
      setEmail(manager?.email);
      setPhoneNumber(manager?.phone);
      setSelectLotsIds(manager?.lots?.map(lot => lot?._id));
      setSelectLots(manager?.lots);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const newErrors = {};
    if (!managerName) newErrors.managerName = "Manager Name is required";
    if (!email) newErrors.email = "Email is required";
    if (!phoneNumber) newErrors.phoneNumber = "Phone Number is required";
    if (selectLots.length === 0)
      newErrors.selectLots = "At least one lot must be selected";
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        const managerData = {
          fullname: managerName,
          email,
          phone: phoneNumber,
          lots: selectLots,
        };
        console.log(managerData, selectLotsIds, managerId, "<<<manager data")
        const { value } = managerId
          ? await props.updateManager(managerId, managerData)
          : await props.addManager(managerData);
        if (value?.success) {
          navigate("/managers");
          dispatch(setAlert("success", value?.message));
        } else {
          setLoading(false);
          dispatch(setAlert("error", value?.message));
        }
      } catch (error) {
        setLoading(false);
        dispatch(setAlert("error", error?.message));
      }
    }
    setLoading(false)
  };

  const handleManagerNameChange = (e) => {
    setManagerName(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, managerName: "" }));
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, phoneNumber: "" }));
  };

  return (
    <>
      {isLoading && <Loading />}
      <section className="mainWrapper">
        <Header />
        <div className="main-wrapper paddzero" id="profile-wrapper">
          <div className="HeadingSection addlote-title">
            <img
              src={leftArrow}
              className="left-arrow"
              onClick={() => navigate(-1)}
            />
            <h4>{managerId ? "Edit Manager" : "Add Manager"}</h4>
          </div>
          <div className="add-lot-block DetailsWrapper">
            <Card
              sx={{ maxWidth: 500, margin: "auto", boxShadow: 3, padding: 2 }}
            >
              <CardHeader title={managerId ? "Edit Manager" : "Add Manager"} />
              <CardContent>
                <form onSubmit={handleSubmit}>
                  <Box display="flex" flexDirection="column" gap={2}>
                    <TextField
                      label="Manager Name"
                      value={managerName}
                      onChange={handleManagerNameChange}
                      error={!!errors.managerName}
                      helperText={errors.managerName}
                      sx={{ flex: 1 }}
                    />
                    <TextField
                      label="Email"
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      error={!!errors.email}
                      helperText={errors.email}
                      sx={{ flex: 1 }}
                    />
                    <TextField
                      label="Phone Number"
                      type="tel"
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      error={!!errors.phoneNumber}
                      helperText={errors.phoneNumber}
                    />
                    <FormControl error={!!errors.selectLots}>
                      <InputLabel id="demo-multiple-checkbox-label">
                        Select Lots
                      </InputLabel>
                      {console.log(selectLotsIds, "<===selectLotsIds")}
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={selectLotsIds}
                        onChange={handleChange}
                        input={<OutlinedInput label="Select Lots" />}
                        renderValue={(selected) =>
                          selected.map((selectedLotId) => {
                            console.log(selectedLotId, "<===selectedLotId")
                            const lotdata = lots?.find(lot => lot?._id === selectedLotId)
                            console.log(lotdata, "<<<lot data")
                            return lotdata?.lotname
                          }).join(", ")
                        }
                        MenuProps={MenuProps}
                      >
                        {lots.map((lot) => (
                          <MenuItem key={lot?._id} value={lot?._id}>
                            <Checkbox
                              checked={selectLotsIds?.some(
                                (lotId) => lotId == lot?._id
                              )}
                            />
                            <ListItemText primary={lot?.lotname} />
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.selectLots && (
                        <Alert severity="error">{errors.selectLots}</Alert>
                      )}
                    </FormControl>
                    <Box mt={2}>
                      <button type="submit" className="btn DarkBtn">
                        {managerId ? "Update" : "Submit"}
                      </button>
                    </Box>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </div>
        </div>
      </section>
    </>
  );
}
