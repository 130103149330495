import React, { useReducer, useRef } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { Pagination } from 'antd';
import searchicon from "../../../images/search.svg";
import location from "../../../../src/images/location.svg";
import cpma from "../../../images/cpma.svg";
import leftArrow from "../../../images/left-arrow.svg";
import Header from "../Citations-Header/container";
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAlert,setLoading } from "../../../store/actioncreator";
import { debounce} from "../../../global";

export default function AdminoperatorComponent(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [operators, setOperators] = useState([])

  const [searchKey, setSearchKey] = useState('');
  const searchInputRef = useRef()

  const initialPageState = {
    total: 0,
    defaultCurrentPage: 1,
    currentPage: 1,
    pageSize: 10
  }

  const [pageState, setPageState] = useReducer((prev, next) => {
    if (next?.currentPage) {
      getOperator(next?.currentPage)
    }
    return { ...prev, ...next }
  }, initialPageState)

  async function getOperator(page) {
    dispatch(setLoading(true))
    try {
      const { value } = await props?.getusers('operator', { page, size: 10 })
      if (value?.success) {
        setOperators(value?.users)
        setPageState({ total: value?.total })
      }
      else {
        dispatch(setAlert('error', value?.message))
      }
    } catch (error) {
      dispatch(setAlert('error', error?.message))
    }
    dispatch(setLoading(false))
  }

  const search = async query => {
    try {
      if (query?.length > 2) {
        const { value } = await props?.searchForOperator('operator', query)
        if (value?.success) {
           setOperators(value?.results)
   
         setPageState({ total: 0 })
        }
        else {
          dispatch(setAlert('error', value?.message))
        }
      }
    } catch (error) {
      dispatch(setAlert('error', error?.message))
    }
  }

  useEffect(() => {
    getOperator(1)
  }, [])

  return (
    <>
      <section className="adminCitations">
        <Header />
        {/* <Header props={props}/> */}
        <div className="citationsSection" id="profile-wrapper">
          <div className="d-flex  align-items-center">
            <img src={leftArrow} className="left-arrow" onClick={() => navigate(-1)} />
            <h4>Operators</h4>
          </div>
          <div className="list-wrapper">
            <div>
              <div className="search-input">
                <button>
                  <img src={searchicon} />
                </button>
                <input 
                type="search" 
                placeholder="Search..."
                ref={searchInputRef}
                onChange={async (e) => {
                  setSearchKey(e?.target?.value)
                  e?.target?.value?.length > 2 && debounce(await search(e?.target?.value), 2000)
                  e?.target?.value?.length === 0 && getOperator(1)
                }}
                 />
              </div>
              <ul className="operator-block">
                {
                  operators.length > 0 ? (
                  operators?.map((operator, index) => {
                    return <li key={index}>
                      <Link to={`/operator/${operator?._id}`}>
                        <div className="logo-img">
                          <img src={operator?.avatar} />
                        </div>
                        <div className="operator-list">
                          <h5>{operator?.fullname}</h5>
                          <p>
                            {operator?.address && <img src={location} />}
                            {operator?.address ? operator?.address : ''}
                            {/* <img src={location} /> */}
                            {/* {operator?.address} */}
                          </p>
                        </div>
                      </Link>
                    </li>
                  })
                  ) 
                  :
                  //  (
                  //   <li>
                  //     <h5>No results found.</h5>
                  //   </li>
                  // )
                  (
                    operators === null || operators?.length === 0 ?
                      searchKey &&
                      <div className="dataText">
                         <h5>No Result Found.</h5>
                      </div>
                      :
                      <div className="dataText">
                      <h5>No Result Found.</h5>
                    </div>
                  )
                }
              </ul>
            </div>
            <div className='pagination-block'>
              <Pagination
                hideOnSinglePage
                defaultCurrent={pageState?.defaultCurrentPage}
                onChange={page => setPageState({ currentPage: page })}
                current={pageState?.currentPage}
                pageSize={pageState?.pageSize}
                total={pageState?.total}
                showSizeChanger={false}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}



























