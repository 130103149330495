import * as React from "react"

const Download = ({ ...props }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 21 21" fill="white" xmlns="http://www.w3.org/2000/svg">
    <path
    fill="currentColor" d="M10.2939 0C9.85188 0.135304 9.50514 0.379069 9.38353 0.850167C9.343 1.00629 9.33643 1.17391 9.33588 1.3366C9.33314 4.9027 9.33369 8.46935 9.33369 12.0355C9.33369 12.1204 9.33369 12.2053 9.33369 12.3395C9.23837 12.2485 9.1836 12.1987 9.13156 12.1467C8.3099 11.3255 7.48988 10.5033 6.66658 9.6838C6.34394 9.36279 5.95338 9.25981 5.51735 9.37977C5.07858 9.50029 4.80141 9.79719 4.69952 10.2393C4.59764 10.6824 4.73896 11.0571 5.05831 11.3754C5.92489 12.2381 6.78873 13.1042 7.65367 13.9691C8.2962 14.6117 8.93765 15.2553 9.58183 15.8963C10.1679 16.4802 10.8313 16.4797 11.4147 15.8968C12.9178 14.3948 14.4209 12.8927 15.9223 11.3891C16.4191 10.8917 16.4657 10.2152 16.0445 9.73474C15.5882 9.21434 14.8268 9.19298 14.3173 9.69749C13.4968 10.5099 12.6833 11.3294 11.8666 12.1456C11.8146 12.1976 11.7598 12.2474 11.6639 12.3389C11.6639 12.2053 11.6639 12.1209 11.6639 12.036C11.6639 8.4562 11.6645 4.87586 11.6634 1.29607C11.6634 0.603662 11.3933 0.235549 10.7371 0.0235549C10.7245 0.0197204 10.7146 0.00821682 10.7037 0.000547788C10.5673 0 10.4309 0 10.2939 0Z"  />
    <path fill="currentColor" d="M0.000142596 16.771C0.0527289 17.0235 0.0943587 17.2788 0.160092 17.5275C0.693623 19.5527 2.51661 20.9846 4.6124 20.9906C8.53555 21.0016 12.4587 21.0043 16.3819 20.9901C18.9202 20.9813 20.9733 18.9134 20.9974 16.3788C21.0012 15.9756 21.0018 15.5725 20.9974 15.1693C20.9897 14.5103 20.4683 13.9888 19.8263 13.9937C19.1892 13.9987 18.6809 14.5081 18.6639 15.16C18.6502 15.6853 18.6863 16.2172 18.6157 16.7343C18.4683 17.8129 17.5032 18.6461 16.4109 18.6532C14.1968 18.6675 11.9821 18.6592 9.7675 18.6598C8.09295 18.6603 6.41841 18.6625 4.74386 18.6587C3.5793 18.6565 2.64972 17.957 2.40761 16.8658C2.33037 16.5179 2.35338 16.1471 2.3364 15.7866C2.32325 15.5073 2.34845 15.223 2.30463 14.9491C2.21698 14.4057 1.77822 14.0359 1.22606 13.9959C0.719918 13.9592 0.23459 14.2917 0.060398 14.7941C0.0406782 14.8516 0.0204102 14.9085 0.000142596 14.9655C0.000142596 15.5686 0.000142596 16.1695 0.000142596 16.771Z" />
  </svg>
  );
};



export default Download
