import Loading from "../../utils/loading/Loading";
import React, { useEffect, useReducer, useState, useRef } from "react";
import Edit from "../../images/Dashboard-edit.svg";
import WhiteViewEdit from "../../images/ic-edit-white.svg";
import DarkViewEdit from "../../images/ic-edit-dark.svg";
import Delete from "../../images/ic-delete.svg";
import DarkViewDelete from "../../images/ic-delete-dark.svg";
import WhiteViewDelete from "../../images/ic-delete-white.svg";
import View from "../../images/IC-view.svg";
import DarkView from "../../images/DarkViewImg.svg";
import WhiteView from "../../images/WhiteViewImg.svg";
import OffenceDeleteModal from "../../utils/modal"
import Header from "../../components/common/Header/container";
import leftArrow from "../../images/left-arrow.svg";
import { useNavigate } from "react-router-dom";
import { setAlert, setLoading } from "../../store/actioncreator";
import { useDispatch } from "react-redux";
import { Pagination } from 'antd';
import NoResultFound from "../../utils/NoResultFound/NoResultFound";
export default function OffenceComponent(props) {
    const dispatch = useDispatch()
    const [pageState, updatePageState] = useReducer((prev, next) => {
        if (next?.currentPage) {
            fetchOffences(next?.currentPage)
        }
        return { ...prev, ...next }
    }, {
        offences: [],
        isActiveSpinner: false,
        editOffenceId: null,
        total: 0,
        defaultCurrentPage: 1,
        currentPage: 1,
        pageSize: 10
    })

    const initialState = {
        offenceName: "",
        description: "",
        fees: ""
    }
    const [state, updateState] = useReducer((prev, next) => {
        return { ...prev, ...next }
    }, initialState)

    const btnRef = useRef(null);

    const editInitialState = {
        offenceName: "",
        description: "",
        fees: ""
    }
    const [editState, updateEditState] = useReducer((prev, next) => {
        return { ...prev, ...next }
    }, editInitialState)

    const [showModal, setShowModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const navigate = useNavigate();

    async function fetchOffences(page) {
        dispatch(setLoading(true))
        try {
            const { value } = await props.getOffenceByOperator({ page, size: 10 })
            if (value?.success) {
                updatePageState({
                    total: value?.total,
                    offences: value['offences']
                })
            }
            else {
                dispatch(setAlert('error', value?.message))
            }
        } catch (error) {
            dispatch(setAlert('error', error?.message))
        }
        dispatch(setLoading(false))
    }

    useEffect(() => {
        fetchOffences(1)
    }, [])


    const [errorforfees, seterrorforfees] = useState();
    const [blankerror, setblankerror] = useState();
    const [blankerror1, setblankerror1] = useState();
    const [blankerror2, setblankerror2] = useState();
    const [blankerror4, setblankerror4] = useState();
    const [blankerror5, setblankerror5] = useState();
    const [blankerror6, setblankerror6] = useState();
    const [blankerror7, setblankerror7] = useState();

    const submit = async (e) => {
        e.preventDefault()
        updatePageState({ isLoading: true })
        setblankerror5(false)
        setblankerror4(false)
        let err = false

        if (state.offenceName.trim() === "") {
            setblankerror("Please enter name")
            err = true
        }
        if (state.description.trim() === "") {
            setblankerror1("Please enter description")
            err = true
        }
        if (state.fees.trim() === "") {
            setblankerror2("Please enter fee")
            err = true
        }

        if (!err) {
            dispatch(setLoading(true))
            try {
                const { value } = await props.addOffence(state)
                if (value?.success) {
                    updateState(initialState)
                    fetchOffences(1)
                    dispatch(setAlert("success", value?.message))
                }
                else {
                    dispatch(setAlert("error", value?.message))
                }
            } catch (error) {
                dispatch(setAlert("error", error?.message))
            }
        }
        dispatch(setLoading(false))
    }

    const handleDeleteClick = (id) => {
        setShowModal(true);
        setDeleteId(id);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };



    const handleDeleteOffence = async () => {
        dispatch(setLoading(true))
        try {
            const { value } = await props.deleteOffence(deleteId)
            if (value?.success) {
                fetchOffences(1)
                setShowModal(false);
                dispatch(setAlert("success", value?.message))
            }
            else {
                dispatch(setAlert("error", value?.message))
            }
        } catch (error) {
            dispatch(setAlert("success", error?.message))
        }
        dispatch(setLoading(false))
    }


    const changeOffenceStatus = async (offenceId, isActive, index) => {
        updatePageState({ isActiveSpinner: index })
        try {
            const { value } = await props.updateOffence(offenceId, { isActive })
            if (value?.success) {
                fetchOffences(pageState?.currentPage)
                updatePageState({ isActiveSpinner: false })
            }
            else {
                dispatch(setAlert("error", value?.message))
                updatePageState({ isActiveSpinner: false })
            }
        } catch (error) {
            dispatch(setAlert("error", error?.message))
            updatePageState({ isActiveSpinner: false })
        }
    }

    const handleEditModal = async (
        {
            offenceName,
            description,
            fees,
            _id
        }
    ) => {
        updatePageState({ editOffenceId: _id })
        updateEditState({
            offenceName,
            description,
            fees
        })
    }

    const handleEditOnChange = e => {

        if (e.target.value[-1] / e.target.value[-1] === 1) {
            // alert("number not allowed")
            console.log("number not allowed")
        } else {
            updateEditState(
                {
                    [e?.target?.name]: e?.target?.value
                }
            )
        }

    }







    const [editerror, setediterror] = useState()

    const onEditOffence = async (e) => {
        e.preventDefault();
        let err = false

        if (editState?.offenceName.length == 0 || editState?.fees.length == 0 || editState?.description?.length == 0) {
            setediterror(true)
            err = true
        }

        if (!err) {

            updatePageState({ isLoading: true })
            try {
                const { value } = await props.updateOffence(pageState?.editOffenceId, editState)
                if (value?.success) {
                    fetchOffences(pageState?.currentPage)
                    // updatePageState({ isLoading: false })
                    dispatch(setAlert("success", value?.message))
                    btnRef?.current?.click()
                }
                else {
                    updatePageState({ isLoading: false })
                    dispatch(setAlert("error", value?.message))
                }
            } catch (error) {
                updatePageState({ isLoading: false })
                dispatch(setAlert("success", error?.message))
            }
        }
    }


    const clearerror = () => {
        setblankerror(false)
        setblankerror1(false)
        setblankerror2(false)
        setblankerror4(false)
        setblankerror5(false)
        setblankerror6(false)
        setblankerror7(false)
    }


    return (
      <>
        <section className="mainWrapper">
          <Header />
          <div className="main-wrapper" id="profile-wrapper">
            <h3>
              <img
                src={leftArrow}
                className="left-arrow"
                onClick={() => navigate(-1)}
              />
              Notices{" "}
            </h3>
            <form onSubmit={submit} className="offence-wrapper">
              <div className="offence-list">
                <label>
                  Name<span className="mandatory Error">*</span>
                </label>
                <input
                  type="text"
                  name="offenceName"
                  // required
                  maxLength={35}
                  onClick={clearerror}
                  value={state["offenceName"]}
                  onChange={(e) => {
                    if (/^\d+$/.test(e.target.value)) {
                      // alert('Numbers are not allowed!');
                      setblankerror4("Numbers are not allowed!");
                      setblankerror(false)
                    } else {
                      const regex = /^[A-Za-z ]+$/;
                      if (regex.test(e.target.value) || e.target.value === "") {
                        updateState({ [e?.target?.name]: e?.target?.value });
                        setblankerror4(false);
                        setblankerror(false)
                        setblankerror1(false)
                        setblankerror2(false)
                      }
                    }
                  }}
                />
                {blankerror && <div className="Error">{blankerror}</div>}
                {blankerror4 && <div className="Error">{blankerror4}</div>}
              </div>
              <div className="offence-list">
                <label>
                  Description<span className="mandatory Error">*</span>
                </label>
                <input
                  type="text"
                  name="description"
                  value={state["description"]}
                  onClick={clearerror}
                  onChange={(e) => {
                    updateState({ [e?.target?.name]: e?.target?.value });
                    seterrorforfees(false);
                    setblankerror(false)
                    setblankerror1(false)
                    setblankerror2(false)
                  }}
                />
                {blankerror1 && <div className="Error">{blankerror1}</div>}
              </div>

              <div className="offence-list">
                <label>
                  Fee<span className="mandatory Error">*</span>
                </label>
                <input
                  type="text"
                  name="fees"
                  onClick={clearerror}
                  value={state["fees"]}
                  maxLength={6}
                  onChange={(e) => {
                    seterrorforfees(false);
                    const input = e.target.value;
                    // const regex = /^[0-9\b]+$/;
                    const regex = /^\d*(\.)?(\d{0,5})?$/;
                    if (input == "" || regex.test(input)) {
                      updateState({ [e?.target?.name]: input });
                      setblankerror5(false);
                      setblankerror4(false);
                      setblankerror(false)
                      setblankerror1(false)
                      setblankerror2(false)
                    } else {
                      // alert("Please enter numbers only.");
                      setblankerror5("Please enter numbers only.");
                      setblankerror(false)
                        setblankerror1(false)
                        setblankerror2(false)
                    }
                  }}
                />
                {blankerror2 && <div className="Error">{blankerror2}</div>}
                {blankerror5 && <div className="Error">{blankerror5}</div>}
              </div>
              <div className="offence-list">
                <label></label>
                <button className="btn DarkBtn" type="submit">
                  Add
                </button>
              </div>
            </form>
            {pageState?.offences?.length > 0 ? (
              <div className="lotsWrapper ">
                <div className="table-responsive">
                  <table className="table offence-table StripTable">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Description </th>
                        <th>Fee</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageState["offences"]?.map((offence, index) => {
                        return (
                          <tr key={offence._id}>
                            <td>{offence?.["offenceName"]}</td>
                            <td>{offence?.["description"]}</td>
                            <td>${offence?.["fees"]}</td>
                            <td>
                              <div
                                className={
                                  offence?.["isActive"]
                                    ? "green LabelStatus"
                                    : "red LabelStatus"
                                }
                              >
                                {offence?.["isActive"] ? "Active" : "Inactive"}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-center">
                                <button
                                  className="ActionsButtons"
                                  onClick={() => {
                                    if(offence?.offenceName == "Auto Reduce Offence") {
                                      dispatch(setAlert('error',"can't edit as it's auto generated for reducing citation"));
                                    }
                                    else{
                                      handleEditModal(offence);
                                    }
                                  }}
                                >
                                  <img
                                    src={DarkViewEdit}
                                    className="DarkImg"
                                    alt="Edit"
                                    data-bs-toggle="modal"
                                    href="#exampleModalToggle"
                                    role="button"
                                  />
                                  <img
                                    src={WhiteViewEdit}
                                    className="WhiteImg"
                                    alt="Edit"
                                    data-bs-toggle="modal"
                                    href="#exampleModalToggle"
                                    role="button"
                                  />
                                </button>
                                <button
                                  className="ActionsButtons"
                                  onClick={() =>{
                                    if(offence?.offenceName == "Auto Reduce Offence") {
                                      dispatch(setAlert('error',"can't delete as it's auto generated for reducing citation"));
                                    }
                                    else if(offence?.["isActive"]){
                                      dispatch(setAlert('error',"Disable offence to delete successfully"));
                                    }
                                    else{
                                      handleDeleteClick(offence?._id)
                                    }}
                                  }
                                >
                                  <img
                                    src={DarkViewDelete}
                                    className="DarkImg"
                                    alt="Delete"
                                  />
                                  <img
                                    src={WhiteViewDelete}
                                    className="WhiteImg"
                                    alt="Delete"
                                  />
                                </button>

                                <button
                                  className="your-lots-btn form-check form-switch"
                                  onClick={() =>
                                    {
                                      if(offence?.offenceName == "Auto Reduce Offence") {
                                        dispatch(setAlert('error',"can't disable as it's auto generated for reducing citation"));
                                      }
                                      else{
                                        changeOffenceStatus(
                                          offence?._id,
                                          !offence?.isActive,
                                          index
                                        )}
                                      }
                                  }
                                >
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      id="togBtn"
                                      checked={offence?.["isActive"]}
                                    />
                                    <div className="slider round"></div>
                                  </label>
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="pagination-block">
                  <Pagination
                    hideOnSinglePage
                    defaultCurrent={pageState?.defaultCurrentPage}
                    onChange={(page) => updatePageState({ currentPage: page })}
                    current={pageState?.currentPage}
                    pageSize={pageState?.pageSize}
                    total={pageState?.total}
                    showSizeChanger={false}
                  />
                </div>
              </div>
            ) : (
              <NoResultFound msgType="Notice" isSearchResult={false} />
            )}
          </div>
        </section>
        <div
          className="modal fade"
          id="exampleModalToggle"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabindex="-1"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalToggleLabel">
                  Edit Notice{" "}
                </h5>

                <button
                  type="button"
                  ref={btnRef}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form className="offence-form" onSubmit={onEditOffence}>
                  <div className="form-group">
                    <label for="text">
                      Name<span className="mandatory Error">*</span>
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="offenceName"
                      maxLength={35}
                      onClick={clearerror}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        // const regex = /^[A-Za-z]+$/;
                        const regex = /^[A-Za-z\s]*$/;
                        if (inputValue === "" || regex.test(inputValue)) {
                          handleEditOnChange(e);
                          setblankerror7(false);
                        } else {
                          // alert("Invalid input. Only letters are allowed.");
                          setblankerror7("Only letter are allowed");
                          setediterror(false)
                        }
                      }}
                      value={editState?.offenceName}
                    />
                    {blankerror7 && <div className="Error">{blankerror7}</div>}
                    {editerror && editState.offenceName <= 0 ? (
                      <div className="Error">Please enter name.</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-group">
                    <label for="text">
                      Fee<span className="mandatory Error">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="fees"
                      name="fees"
                      maxLength={8}
                      onClick={clearerror}
                      onChange={(e) => {
                        const input = e.target.value;
                        const regex = /^[0-9\b]+$/;
                        if (input == "" || regex.test(input)) {
                          handleEditOnChange(e);
                          setblankerror6(false);
                        } else {
                          // alert("Text not allowed!")
                          setblankerror6("Text not allowed!");
                          setediterror(false)
                        }
                      }}
                      value={editState?.fees}
                    />
                    {blankerror6 && <div className="Error">{blankerror6}</div>}
                    {editerror && editState?.fees <= 0 ? (
                      <div className="Error">Please enter fees.</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-group">
                    <label>
                      Description<span className="mandatory Error">*</span>
                    </label>
                    <textarea
                      rows={8}
                      col={5}
                      className="form-control"
                      name="description"
                      onChange={handleEditOnChange}
                      // onClick = {removeErrorMessage}
                      value={editState?.description}
                    ></textarea>
                    {editerror && editState?.description <= 0 ? (
                      <div className="Error">Please enter fees.</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="d-flex">
                    {/* <button type="button" className="btn DarkBtn" */}
                    <button
                      type="submit"
                      className="btn DarkBtn"
                      // data-bs-dismiss="modal"
                      // onClick={onEditOffence}
                    >
                      Update Notice{" "}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModalToggle2"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel2"
          tabindex="-1"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalToggleLabel2">
                  Modal 2
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                Hide this modal and show the first with the button below.
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-primary"
                  data-bs-target="#exampleModalToggle"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                >
                  Back to first
                </button>
              </div>
            </div>
          </div>
        </div>

        <OffenceDeleteModal
          show={showModal}
          onClose={handleCloseModal}
          onSubmit={handleDeleteOffence}
          title="Notice"
        />
      </>
    );
}
