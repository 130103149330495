import { connect } from "react-redux";
import { get_all_disputed_citations_operator, search_disputed_citations_operator } from "../../store/home/duck";
import OperatorDisputedCitations from "./OperatorDisputedCitations.js";

const OperatorDisputedCitationsContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    get_all_disputed_citations_operator,search_disputed_citations_operator
  }
)(OperatorDisputedCitations);

export default OperatorDisputedCitationsContainer;
