import React from "react";
import DatePicker from "react-datepicker";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Pagination, Switch } from 'antd';
import Modal from "react-modal";
import { Link } from "react-router-dom";
import singleedit from "../../../images/single-edit.svg";
import DownloadIcon from "../../../images/DownloadIcon.svg";
import search from "../../../images/search.svg";
import location from "../../../../src/images/location.svg";
import { Navigate, useNavigate } from "react-router-dom";
import Edit from "../../../images/edit.svg";
import leftArrow from "../../../images/left-arrow.svg";
import Header from "../Citations-Header/container";
import Download from "../../../components/svgIcons/Download";
import { useEffect, useState, useReducer } from 'react';
import { useParams } from "react-router-dom";
import { setAlert,setLoading } from "../../../store/actioncreator";
import { useDispatch } from "react-redux";
import { async } from "rxjs";
// import { saveAs } from 'file-saver';
import { CSVLink } from 'react-csv';
import moment from "moment-timezone"


export default function OperatorListComponent(props) {

  const params = useParams()
  const dispatch = useDispatch()
  const tz = moment.tz.guess()


  const navigate = useNavigate();
  const [data, setdata] = useState({isTaggrSurveyEnable: true})
  const [pageData, setPageData] = useState({})
  const [startDate, setStartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [name, setName] = useState()

  useEffect(() => {
    fetchName();
    getOperatorDetails();
    getLotsByOperator(1);
    hideMenu();
  }, [])


  const initialPageState = {
    total: 0,
    defaultCurrentPage: 1,
    currentPage: 1,
    pageSize: 10
  }

  const [pageState, setPageState] = useReducer((prev, next) => {
    if (next?.currentPage) {
      getLotsByOperator(next?.currentPage)
    }
    return { ...prev, ...next }
  }, initialPageState)

  const fetchName = async () => {
    try {
      const { value } = await props.user()
      if (value?.success) {
        setName(value)
      } else {
        dispatch(setAlert("error", value?.message))
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message))
    }
  }




const [operatorname , setoperatorname] = useState();

  const getOperatorDetails = async () => {
    try {
      const id = params?.id
      const { value } = await props.getuserdetails(id)
      if (value.success) {
        setdata({
          fullname: value?.user?.fullname,
          address: value?.user?.address,
          email: value?.user?.email,
          phone: value?.user?.phone,
          avatar: value?.user?.avatar,
          isTaggrSurveyEnable: value?.user?.isTaggrSurveyEnable ?? true
        })
        setPageData(value?.user)
        setoperatorname(value?.user?.fullname)
        // setPageState({ total: value?.total })
      } else {
        dispatch(setAlert("error", value?.message))
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message))
    }
  }


  const [lotdata, setlotdata] = useState({})

  async function getLotsByOperator(page) {
    dispatch(setLoading(true))
    try {
      const id = params?.id
      const { value } = await props.getlotdetails(id, { page, size: 10, startDate, endDate })
      if (value.success) {
        setlotdata(value)
        setPageState({ total: value?.total })
      } else {
        dispatch(setAlert("error", value?.message))
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message))
    }
    dispatch(setLoading(false))
  }

  async function get_all_report_LotsByOperator() {
    try {
      const id = params?.id
      const { value } = await props.getlotdetails(id, { startDate, endDate })
      if (value?.success) {
        let header = ["Lot Name","Address",  "No. of Tags", "No. of Visits", "Operator's Revenue($)"];
        const data = [
          header,
          ...value?.lots.map(user => [
            user?.lotname ?? "--",
            `"${user?.address ?? "--"}"`,
            user?.number_of_tags ?? 0,
            user?.visits ?? 0,
            user?.total_operator_revenue_amount ?? "0"
          ])
        ];
        const csv = data.map(row => row.join(",")).join("\n");
        const blob = new Blob([csv], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        // link.download = "Alldata.csv";
        link.download = `All-${operatorname}_admin_dashboard_report_${getCurrentDateTime()}.csv`;
        link.click();
        URL.revokeObjectURL(url);
      }
      else {
        dispatch(setAlert("error", value?.message))
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message))
    }
  }


  const getCurrentDateTime = () => {
    const now = moment().tz(tz);
    return now.format("YYYY-MM-DD_HH-mm-ss");
  };

  // async function get_all_report_LotsByOperator() {
  //         try {
  //             const { value } = await props?.getlotdetails({ startDate, endDate });
  //             if (value?.success) {
  //                 console.log(value, "************");
  //                 let header = ["Operator", "Address", "No. of Tags", "No. of Visits", "Total Revenue($)"];
  //                 const data = [
  //                     header,
  //                     ...value?.users.map(user => [
  //                         user?.fullname ?? "--",
  //                         `"${user?.address ?? "--"}"`,
  //                         0,
  //                         user?.visits ?? 0,
  //                         user?.total_operator_revenue_amount ?? "0"
  //                     ])
  //                 ];
  //                 const csv = data.map(row => row.join(",")).join("\n");
  //                 const blob = new Blob([csv], { type: "text/csv" });
  //                 const url = URL.createObjectURL(blob);
  //                 const link = document.createElement("a");
  //                 link.href = url;
  //                 link.download = "Alldata.csv";
  //                 link.click();
  //                 URL.revokeObjectURL(url);
  //             }
  //         } catch (error) {
  //             dispatch(setAlert('error', error?.message));
  //         }
  //     }



  // function handleSubmit() {
  //     getLotsByOperator(1);
  // }

  function handleSubmit() {
    if (!startDate || !endDate) {
      dispatch(setAlert("error", "Please select start date and end date"))
      console.log("Please select start and end date");
    } else {
      dispatch(setLoading(true))
      getLotsByOperator(1);
    }
    setTimeout(() => {
      dispatch(setLoading(false))
    }, 300);
  }


  const [editMode, setEditMode] = useState(false);
  const [error, seterror] = useState()

  const handleEditClick = async (e) => {
        if (e.key === "Enter" || e === "submit") {

      seterrmessage(false)
      seterrmessage1(false)

      try {

        setEditMode(!editMode);

        // if (!!editMode) {
          const id = params?.id

          let err = false

          if (data?.fullname?.trim() === "") {
            err = true
            seterror(true)
          }
          if (data?.phone?.trim() === "") {
            err = true
            seterror(true)
          }
          if (data?.email?.trim() === "") {
            err = true
            seterror(true)
          }
          if (data?.address?.trim() === "") {
            err = true
            seterror(true)
          }

          if (!err) {


            const { value } = await props.editprofile({ user: data, userId: id });
            if (value?.success) {
              dispatch(setAlert('success', value?.message))
              getOperatorDetails();
            } else {
              dispatch(setAlert('error', value?.message))
            }
          }

        // }

      } catch (error) {
        dispatch(setAlert('error', error?.message))
      }
    }
  };



  // const handleChange = (e) => {
  //     setdata({ ...data, [e?.target?.name]: e.target.value })
  //     console.log(data, "------>");
  // };

  const [errmessage, seterrmessage] = useState()
  const [errmessage1, seterrmessage1] = useState()


  const handleChange = (e) => {
    const { name, value } = e.target;

    let err1 = false

    if (name === 'fullname' && /\d/.test(value)) {
      err1 = true
      // alert('Name field should not contain numbers');
      seterrmessage("Number not allowed!")
    } else {
      seterrmessage("")
    }

    if (name === 'phone' && /\D/.test(value)) {
      err1 = true;
      // alert('Phone field should only contain numbers');
      seterrmessage1("Text not allowed")
    } else {
      seterrmessage1("")
    }

    if (!err1) {
      setdata({ ...data, [name]: value });
      console.log(data, "------>");
    }
  };



  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
  }


  const [checked, setChecked] = useState(false);

  const handleChangeforcheckbox = () => {
    setChecked(!checked);
  };


  const [currentTime, setCurrentTime] = useState(new Date());

  const updateTime = () => {
    setCurrentTime(new Date());
  };

  const [selectedLot, setSelectedLot] = useState(null);

  const hideMenu = () => {
    var element = document.getElementById("profile-wrapper");
    var menu = document.getElementById("test_menu");
    element.classList.add("left-wrapper");
    menu.classList.remove("showmenu");
  }

  return (
    <>
      <section className="adminDashboard">
        <Header />
        <div className="adminreport-wrapper" id="profile-wrapper">
          <div className="d-flex  align-items-center">
            <img
              src={leftArrow}
              className="left-arrow"
              onClick={() => navigate(-1)}
            />
            <h4>Operators</h4>
          </div>
          <div className="report-list-wrapper">
            <div className="pd-block">
              <div className="operators-title">
                <div className="d-flex align-items-center">
                  <div className="logo-img">
                    {/* <img src={shoreline} /> */}
                    <img src={pageData?.avatar} alt="avatar" />
                  </div>
                  <div className="logo-text">
                    {/* <h5>Shoreline Parking Solutions</h5> */}
                    <h5>{pageData?.fullname}</h5>
                    <p className="d-flex align-items-center">
                      {/* <img src={location} /> */}
                      {/* {data?.user?.address ? data?.user?.address : ''} */}
                      {pageData?.address && <img src={location} />}
                      {pageData?.address ? pageData?.address : ''}
                    </p>
                  </div>
                </div>
                {/* <button className="btn DarkBtn"><img src={downloadicon} />Download</button> */}
              </div>
              <div className="date-picker-block">
                <div className="d-flex align-items-center border-block">
                  <div className="date-picker-list">
                    <label>Start Date</label>
                    <DatePicker
                      selected={startDate}
                      // onChange={(date) => setStartDate(date)}
                      onChange={(date) =>
                        setStartDate(moment(date).tz(tz).toDate())
                      }
                      placeholderText="--/--/----"
                      className="date-picker"
                      maxDate={new Date()}
                    />
                  </div>
                  <div className="date-picker-list">
                    <label>End Date </label>
                    <DatePicker
                      selected={endDate}
                      // onChange={(date) => setendDate(date)}
                      // onChange={(date) =>
                      //     setendDate(moment(date).tz(tz).toDate())
                      // }
                      onChange={(date) => {
                        const newDate = moment(date).tz(tz);
                        newDate.set({ hour: 23, minute: 59, second: 59 });
                        setendDate(newDate.toDate());
                      }}
                      placeholderText="--/--/----"
                      className="date-picker"
                      minDate={startDate}
                      maxDate={new Date()}
                      disabled={!startDate}
                    />
                  </div>
                </div>
                <div className="operator-btns">
                  <div className="border-block-btn"><button className="btn DarkBtn" onClick={handleSubmit}>Submit</button></div>
                  <button className="btn DarkBtn report-btn"
                    onClick={async () => {
                      await get_all_report_LotsByOperator();
                    }}
                  ><Download />All Report</button>
                </div>
              </div>
            </div>

            <ul class="nav nav-pills">
              <li class="nav-item">
                <a class="nav-link active" data-bs-toggle="pill" href="#home">Report</a>
              </li>
              <li class="nav-item border-left-right">
                <a class="nav-link" data-bs-toggle="pill" href="#menu1">Lot Details</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="pill" href="#menu2">Operators Detail</a>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane  active" id="home">
                <div className="table-block">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Lot</th>
                          <th>Address</th>
                          <th>No. of Tags</th>
                          <th>No. of Visits</th>
                          <th>Operator's Revenue</th>
                          <th>Report</th>
                        </tr>
                      </thead>
                      <tbody>

                        {
                          (lotdata?.lots && lotdata?.lots.length > 0) ? (
                            lotdata?.lots?.map((val, index) => {
                              const data = [
                                ['Lot Name',"Address", 'No. of Tags', 'No. of Visits', "Operator's Revenue($)"],
                                [val?.lotname ?? '-',val?.address ?? "", val?.number_of_tags ?? 0, val?.visits ?? 0, val?.total_operator_revenue_amount ?? 0],
                              ];

                              return (
                                <tr key={index}>
                                  <td>{val?.lotname ?? ""}{val?.status === "deleted" && <strong> [Deleted]</strong>}</td>
                                  <td>{val?.address ?? ""}</td>
                                  <td>{val?.number_of_tags ?? 0}</td>
                                  <td>{val?.visits ?? 0}</td>
                                  <td>${val?.total_operator_revenue_amount ?? 0}</td>
                                  <td>
                                    <CSVLink data={data} filename={`${val?.lotname}_${operatorname}_admin_dashboard_report_${getCurrentDateTime()}.csv`} className="btn DarkBtn"> <Download />Download</CSVLink>
                                  </td>
                                </tr>
                              );
                            })
                          )
                            :
                            (
                              lotdata?.lots === null || lotdata?.lots?.length === 0 ?
                                <div className="dataText">
                                  <h5>No Result Found</h5>
                                </div>
                                :
                                <div className="dataText">
                                  <h5></h5>
                                </div>
                            )
                        }

                      </tbody>
                    </table>
                  </div>
                  {/* <div className='pagination-block'><Pagination defaultCurrent={6} total={600} /></div> */}
                  <div className='pagination-block'>
                    <Pagination
                      hideOnSinglePage
                      defaultCurrent={pageState?.defaultCurrentPage}
                      onChange={page => setPageState({ currentPage: page })}
                      current={pageState?.currentPage}
                      pageSize={pageState?.pageSize}
                      total={pageState?.total}
                      showSizeChanger={false}
                    />
                  </div>

                </div>

              </div>
              <div class="tab-pane  fade" id="menu1">
                <div className="table-block ">
                  <div className="table-responsive clickableLot">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Lot</th>
                          <th>Address</th>
                          <th>Drop Box Code</th>
                          <th>Permits Allowed</th>
                          <th>Enforcement Options</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>

                        {
                          (lotdata?.lots && lotdata?.lots.length > 0) ? (
                            lotdata?.lots?.map((val, idx) => {
                              let classStr = "green";


                              const sessionOut = moment(val?.checkIn?.sessionOut);
                              const checkInTime = moment(val?.checkIn?.checkInTime);
                              const currentTime = moment();
                              const timeDiff = moment.duration(currentTime.diff(sessionOut));

                              // const checkInTimestamp = new Date(checkInTime).getTime() / 1000;
                              // const sessionOutTimestamp = new Date(sessionOut).getTime() / 1000;

                              // const sessionDurationSeconds = checkInTimestamp - sessionOutTimestamp;
                              // console.log(sessionDurationSeconds, "----->>>>>");

                              let timeStr;

                              if (timeDiff.asSeconds() < 60) {
                                timeStr = `${Math.floor(timeDiff.asSeconds())}sec`;
                                classStr = "green";
                              } else if (timeDiff.asMinutes() <= 60) {
                                timeStr = `${Math.floor(timeDiff.asMinutes())} min`;
                                classStr = "yellow";
                              } else if (timeDiff.asHours() <= 24) {
                                let mins = Math.floor(timeDiff.asMinutes() % 60);
                                timeStr = `${Math.floor(timeDiff.asHours())} hrs ${mins <= 1 ? mins + " min" : mins + " min"}`;
                                classStr = "red";
                              } else {
                                let days = Math.floor(timeDiff.asDays());
                                let hours = Math.floor(timeDiff.asHours() % 24);
                                timeStr = `${days} day${days > 1 ? "s" : ""} ${hours} hrs `;
                                classStr = "red";
                              }

                              return (
                                <tr key={idx}>

                                  <td data-bs-toggle="modal" href="#exampleModalToggle" role="button" className="lotDetailModal"
                                    onClick={() => setSelectedLot(val)}
                                  >{val?.lotname}
                                  </td>
                                  <td>{val?.address}</td>
                                  <td>{val?.dropbox?.dropboxCode}</td>
                                  {/* <td>{val?.permitAllowed ? "True" : "False"}</td> */}
                                  <td>  {val?.permitAllowed ? "Yes" : "No"}</td>

                                  <td>{val?.taggingType?.sort()?.map((type, i) => {
                                    let value = type === "mailIn" ? `Mail-In${val?.taggingType?.length - 1 !== i ? " | " : " "}` : `Tag Vehicle${data?.taggingType?.length - 1 !== i ? "  " : " "}`
                                    return value
                                  })}
                                  </td>

                                  <td>
                                    {/* <div class="green">{val?.checkIn?.checkInTime}</div> */}
                                    {/* <div class={classStr}>{timeStr}</div> */}
                                    {/* <div class={classStr}>
                                                                            {typeof val?.checkIn === "undefined" ? "-" : timeStr}
                                                                        </div> */}
                                    <div class={classStr}>
                                      {typeof val?.checkIn === "undefined" ? "-" : (timeDiff.asSeconds() < 0 ? "Checked-In " : timeStr)}
                                    </div>

                                  </td>
                                </tr>

                              )
                            })
                          ) : (
                            <tr>
                              <td>
                                <div className="dataText">
                                 <h5>No Result Found</h5> 
                                </div>
                              </td>
                            </tr>
                          )

                        }
                        <tr>
                          {/* <td data-bs-toggle="modal" href="#exampleModalToggle" role="button">Mountain View Parking </td>
                                                    <td>3330 S Ocean Blvd, 618C, Highland Beach, FL</td>
                                                    <td>123456</td>
                                                    <td>Yes</td>
                                                    <td>     <div className="tagging-types">
                                                        <label>Mail-in</label>
                                                    </div></td>
                                                    <td>
                                                        <div class="green">10 mins</div>
                                                    </td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* <div className='pagination-block'><Pagination defaultCurrent={6} total={500} /></div> */}
                  <div className="pagination-block">
                    <Pagination
                      hideOnSinglePage
                      defaultCurrent={pageState?.defaultCurrentPage}
                      onChange={(page) => setPageState({ currentPage: page })}
                      current={pageState?.currentPage}
                      pageSize={pageState?.pageSize}
                      total={pageState?.total}
                      showSizeChanger={false}
                    />
                  </div>
                </div>
              </div>
              <div class="tab-pane  fade" id="menu2">
                <div className="operator-details">
                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      <label>Operators Name</label>
                      <div
                        className="operator-detail-list"
                        onClick={() => {
                          setEditMode(true);
                          seterror(false);
                          seterrmessage(false);
                          seterrmessage1(false);
                        }}
                      >
                        <input
                          type="text"
                          value={data?.fullname}
                          id="text"
                          name="fullname"
                          onChange={handleChange}
                          onKeyDown={handleEditClick}
                          disabled={!editMode}
                        />
                        <img
                          src={singleedit}
                          onClick={() => handleEditClick("submit")}
                        />
                      </div>
                      {errmessage && <p className="Error">{errmessage}</p>}
                      {error && data?.fullname <= 0 ? (
                        <div className="Error">Please enter fullname</div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <label>Address</label>
                      <div
                        className="operator-detail-list"
                        onClick={() => {
                          setEditMode(true);
                          seterror(false);
                          seterrmessage(false);
                          seterrmessage1(false);
                        }}
                      >
                        <input
                          type="address"
                          value={data?.address ?? ""}
                          id="address"
                          name="address"
                          onChange={handleChange}
                          disabled={!editMode}
                          onKeyDown={handleEditClick}
                        />
                        <img
                          src={singleedit}
                          onClick={() => handleEditClick("submit")}
                        />
                      </div>
                      {error && data?.address <= 0 ? (
                        <div className="Error">Please enter address</div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <label>Email Address</label>
                      <div
                        className="operator-detail-list"
                        onClick={() => {
                          setEditMode(true);
                          seterror(false);
                          seterrmessage(false);
                          seterrmessage1(false);
                        }}
                      >
                        <input
                          type="email"
                          value={data?.email}
                          onChange={handleChange}
                          id="email"
                          name="email"
                          onKeyDown={handleEditClick}
                          disabled={!editMode}
                        />
                        <img
                          src={singleedit}
                          onClick={() => handleEditClick("submit")}
                        />
                      </div>
                      {error && data?.email <= 0 ? (
                        <div className="Error">Please enter email</div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <label>Contact No</label>
                      <div
                        className="operator-detail-list"
                        onClick={() => {
                          setEditMode(true);
                          seterror(false);
                          seterrmessage(false);
                          seterrmessage1(false);
                        }}
                      >
                        <input
                          type="text"
                          // value={data?.phone ?? ""}
                          value={data.phone && data.phone === "0000000000" ?  '' : data.phone}
                          id="number"
                          name="phone"
                          maxLength={10}
                          onChange={handleChange}
                          onKeyDown={handleEditClick}
                          disabled={!editMode}
                        />
                        <img
                          src={singleedit}
                          onClick={() => handleEditClick("submit")}
                        />
                      </div>
                      {errmessage1 && <p className="Error">{errmessage1}</p>}
                      {error && data?.phone <= 0 ? (
                        <div className="Error">
                          Please enter contact number
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-3 col-md-6">
                      {console.log(data, "<<<data")}
                      Taggr Survey &nbsp;&nbsp;
                      <Switch
                        handleBg="#FCFF00"
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={data?.isTaggrSurveyEnable ?? true}
                        onClick={() => setdata({...data, isTaggrSurveyEnable: !data?.isTaggrSurveyEnable})}
                      />
                    </div>
                  </div>
                </div>
                <div className="pt-4 d-flex justify-content-center">
                  <button
                    className="DarkBtn m-auto"
                    onClick={() => handleEditClick("submit")}
                  >
                    {" "}
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade operator-modal"
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleLabel2">
                {selectedLot?.lotname}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <label>Lot Name</label>
              <p>
                {selectedLot?.lotname}
                <img src={Edit} />
              </p>
              <label>Address</label>
              <p>
                {selectedLot?.address}
                <img src={Edit} />
              </p>
              {/* <label>Report</label> */}
              <div className="date-picker">
                {/* <div className="date-picker-list">
                                    <label>Start Date</label>
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="date-picker" />
                                </div> */}
                {/* <div className="date-picker-list">
                                    <label>End Date</label>
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="date-picker" />
                                </div> */}
              </div>
              <div className="d-flex align-items-center justify-content-between">
                {/* <button className="btn DarkBtn"><Download />Download</button> */}
                <div>
                  {/* <div className="switch-toggle"><Download />
                                        <label class="switch">
                                            <input type="checkbox" />
                                            <span class="slider round"></span>
                                        </label>
                                    </div> */}
                  <div className="switch-toggle">
                    {/* <p>Deactivate</p> */}
                    {checked ? <p>Activate</p> : <p>Deactivate</p>}
                    <label class="switch">
                      {/* <input type="checkbox" checked /> */}
                      <input
                        type="checkbox"
                        checked={checked}
                        onChange={handleChangeforcheckbox}
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
