import React from "react";
import Modal from "react-modal";
import { useParams } from "react-router-dom";
import { Pagination } from 'antd';
import search from "../../images/search.svg";
import location from "../../../src/images/location.svg";
import citationImage from "../../images/citation-image.png";
import image from "../../images/image.svg";
import violation from "../../images/violations.svg";
import Details from "../../images/details.svg";
import leftArrow from "../../images/left-arrow.svg";
// import Header from "./Citations-Header/container";
import Header from "../../components/common/Header/container";
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { setAlert } from "../../store/actioncreator";
import { useDispatch } from "react-redux";
import { Two_decimals, capitalize } from "../../global";
export default function CitationsInformationComponent(props) {
    // const { id } = useParams()
    const params = useParams();
    const dispatch = useDispatch();

    const [data, setdata] = useState({})


    useEffect(() => {
        callApi();
    }, [])



    const callApi = async () => {
        try {
            const id = params.id
            const { value } = await props.get_single_citation_details(id)
            if (value.success) {
                setdata(value?.citation)
            } else {
                dispatch(setAlert("error", value?.message))
            }
        } catch (error) {
            dispatch(setAlert("error", error?.message))
        }
    }




    const navigate = useNavigate();
    return (
        <>
            <section className="adminCitations">
                <Header />
                <div className="citationsSection paddzero" id="profile-wrapper">
                    <div className="title-wrapper">
                        <div className="d-flex align-items-center">
                            <img src={leftArrow} className="left-arrow" onClick={() => navigate(-1)} />
                            <h4>Notices</h4>
                        </div>
                    </div>
                    <div className="cirtation-wrapper">
                        <div className="details-block-wrapper">
                            <h6><img src={Details} />Notice Details</h6>
                            <div className="details-wrapper-block">
                                <div className="details-list">
                                    <label>Notice Number</label>
                                    <input
                                        value={data?.tiretag_citation_data?.citation_number ?? data?.parkpliant_reference_id ?? ""}
                                        type="text" id="text"
                                        className="form-control"
                                        disabled />
                                </div>
                                <div className="details-list">
                                    <label>Vehicle</label>
                                    <input
                                        value={data?.vehicle?.licensePlate ?? ""}
                                        type="text"
                                        id="text"
                                        className="form-control"
                                        disabled />
                                </div>
                                <div className="details-list">
                                    <label>Location</label>
                                    <input value={data?.lot?.address ?? ""} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>Issued by </label>
                                    <input value={data?.user?.fullname ?? ''} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>Tire Tag S. No.</label>
                                    <input value={data?.tireTagSerialNumber ?? ""} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>Release code</label>
                                    <input value={data?.tag_release_code} type="text" id="text" className="form-control" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="details-block-wrapper">
                            <h6><img src={violation} />Violation Fees and Details</h6>
                            <div className="details-wrapper-block">

                                <div className="details-list">
                                    <label>Violation code(s)</label>
                                    <input value={data?.offence?.offenceName} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>Violation fee</label>
                                    <input value={data?.break_down_amount?.offence_fees > 60 ? data?.break_down_amount?.offence_fees + 35 : data?.break_down_amount?.offence_fees } type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>Payment due</label>
                                    <input value={data?.paid ? 0 : data?.amount} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>Location</label>
                                    <input value={data?.lot?.address ?? ""} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>Holding fee</label>
                                    <input value={Two_decimals(data?.break_down_amount?.holding_fees - data?.break_down_amount?.stripe_fees_holding) ?? ""} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>Payment status</label>
                                    <input value={capitalize(data?.payment_status) ?? ''} type="text" id="text" className="form-control" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="details-block-wrapper">
                            <h6><img src={Details} />Vehicle Details</h6>
                            <div className="details-wrapper-block">

                                <div className="details-list">
                                    <label>Vehicle plate</label>
                                    <input value={data?.vehicle?.licensePlate ?? ""} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>State</label>
                                    <input value={data?.state ?? ""} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>Make</label>
                                    <input value={data?.vehicle?.make ?? ""} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list ">
                                    <label>Model</label>
                                    <input value={data?.vehicle?.model ?? ""} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">

                                    <label>Color</label>
                                    <input value={data?.vehicle?.color ?? ""} type="text" id="text" className="form-control" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="images-wrapper">
                            <h6><img src={image} />Image</h6>
                            <div className="details-wrapper-block">

                                <div className="details-list">

                                    <div className="citations_image">
                                        <img src={data?.images?.licensePlateImage ?? data?.images?.license_plate_image} />
                                    </div>
                                </div>

                                <div className="details-list">

                                    <div className="citations_image">
                                        <img src={data?.images?.signageImage ?? data?.images?.dashboard_image} />
                                    </div>
                                </div>
                                <div className="details-list">

                                    <div className="citations_image">
                                        <img src={data?.images?.windowDecalImage} />
                                    </div>
                                </div>

                                <div className="details-list">

                                    <div className="citations_image">
                                        <img src={data?.images?.tireTagWheelImage} />
                                    </div>
                                </div>
                                {
                                    data?.images?.optionalImage?.length>0 &&
                                    data?.images?.optionalImage.map((item) => <div className="details-list">
                                        <div className="citations_image">
                                            <img src={item} />
                                        </div>
                                    </div>)
                                }
                            </div>
                        </div>

                        <div className="details-wrapper ">

                            <h6><img src={Details} />Remarks</h6>
                            <p>{data?.remark}</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
