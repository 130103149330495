import { connect } from "react-redux";
import { logins, user, getusers ,searchForOperator} from "../../../store/home/duck";
import AdminoperatorComponent from "./admin_operators_component";

const AdminoperatorContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    logins,
    user,
    getusers,
    searchForOperator
  }
)(AdminoperatorComponent);

export default AdminoperatorContainer;
