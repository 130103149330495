import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs, { Dayjs } from "dayjs";
import downArrow from "../../images/down-arrow.svg";
import statsMonth from "../../images/stats-month.png";
import statspayout from "../../images/stats-payout.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import statsDay from "../../images/stats-day.png";
import Modal from "react-bootstrap/Modal";
import IcMail from "../../images/ic-mail.svg";
import IcCall from "../../images/ic-call.svg";
import { Navigate } from "react-router-dom";
import Header from "../../components/common/Header/container";
import moment from "moment-timezone"
import CircularProgressBar from "../../utils/circularProgressBar/CircularProgressBar"
import StarRating from "../../utils/starRating/StarRating";
import TrashIcon from "../../utils/trash/TrashIcon";

export default function DashboardComponent(props) {
  const tz = moment.tz.guess()
  const [startDate, setStartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [operator, setOperator] = useState();
  const [value, setValue] = useState(dayjs("2014-08-18T21:11:54"));
  const [open, setOpen] = React.useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [lots, setLots] = useState([]);
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [dbcode, setDbcode] = useState("");
  const [dblocation, setDblocation] = useState("");
  const [lotRul, setLotRul] = useState("");
  const [clrfld, setClrfld] = useState("");
  const [filename1, setFilename1] = useState("");
  const [filename2, setFilename2] = useState("");
  const [filename3, setFilename3] = useState("");
  const [droplast, setDroplast] = useState("");
  const [address, setAddress] = useState("");
  const [isFetched, setisFetched] = useState(false);
  const [imageName1, setImageName1] = useState();
  const [imageName2, setImageName2] = useState();
  const [imageName3, setImageName3] = useState();
  const [notemptyModal, setnotemptyModal] = useState(false);
  // const [value, setValue] = useState(dayjs("2014-08-18T21:11:54"));
  const [value2, setValue2] = useState(dayjs("2014-08-18T21:11:54"));
  const [selectedLot, setSelectedLot] = useState("All")

  const handleChange1 = (e) => {
    setValue(e);
  };
  useEffect(() => {
    // checkOperator();
    getOPeratorId();
    statisticsoperator();
  }, []);


  const [statisticsdata, setstatisticsdata] = useState()

  const statisticsoperator = async (lotId) => {
    try {
      const { value } = await props.statistics_operator(lotId ? lotId : selectedLot);
      if (value?.success) {
        setstatisticsdata(value);
      }
    } catch (error) {
      console.log(error.message, "<<<user error");
    }
  };




  const checkOperator = async () => {
    const { value } = await props.user();
    if (value.user.role === "operator") {
      Navigate("/yourlots");
      getOPeratorId();
    } else {
      Navigate("login");
    }
  };
  const handleClick = () => setOpen(true);
  const getOPeratorId = async () => {
    const { value } = await props.user();
    if (value?.success) {
      getLotList(value);
      setOperator(value);
    }
  };
  const handleChange2 = (e) => {
    setValue2(e);
  };
  const getLotList = async (value) => {
    const value1 = await props.lotprovider({ id: value.user._id });
    if (value1?.value?.lots) {
      setLots(value1?.value?.lots);
    }
  };
  // const handleShow = () => {
  //   setShow(true);
  // };

  const deleteLot = async (id) => {
    const value1 = await props.deleteyourlot({ id: id });
    if (value1?.value?.lots) {
      setLots(value1?.value?.lots);
      getLotList();
    }
  };
  const handleClose = () => {
    setShow(false);
  };

  const onAdd = async (e) => {
    e.preventDefault();
    const value1 = await props.addlots({
      operator: operator.user._id,
      lotRules: JSON.stringify(lotRul),
      address: address,
      paymentProviders: JSON.stringify(["63c67c13657b1076547dcead"]),
      lotname: name,
      dropbox: JSON.stringify({
        dropboxCode: dbcode,
        dropboxLocation: {
          latitude: dblocation,
          longitude: droplast,
        },
      }),
      parkingLot: filename1,
      dropBox: filename2,
      signage: filename3,
    });
    if (value1?.success) {
      setShow(false);
      setisFetched(true);
      getOPeratorId();
    } else {
      setnotemptyModal(true);
    }
    // if (value1?success) {
    //   setShowList(value1?.value?.lots)
    //   getLotList();
    // }
  };
  return (
    <section className="mainWrapper">
      <Header />
      <div className="main-wrapper" id="profile-wrapper">
        <div className="stats-container-with-select">
        <h2>Stats</h2>
        <select
          className="CustomSelect"
          value={selectedLot}
          onChange={(e) => {
            setSelectedLot(e.target?.value);
            statisticsoperator(e.target?.value)
             console.log("Selected Lot", e.target.value);
          }}
        >
          <option value="All">All</option>
         {lots?.map((lot, idx) => (
             <option key={idx} value={lot?._id}>
                 {lot?.address}
             </option>
         ))}
        </select>
        </div>
        <ul className="satas-wrapper">
          <li>
            <div className="stats-img">
              <img src={statsMonth} />
            </div>
           <h1>{statisticsdata?.statistics?.number_of_tags ?? 0}</h1>
            <p>Tags This Week</p>
          </li>
          <li>
            <img src={statsDay} />
              <h1>{statisticsdata?.statistics?.visits ?? 0}</h1>
            <p>Visits This Week</p>
          </li>
          <li>
            <img src={statspayout} />
            <h1>${statisticsdata?.statistics?.payout_amount ?? 0}</h1>
            <p>Expected Payout</p>
          </li>
        </ul>
        <h4>Lot Monitor</h4>
        <ul className="satas-wrapper rating-align-text">
          <li>
          <CircularProgressBar percentage={statisticsdata?.survey_statistics?.occupancy?.percentage ?? 0} />
            <p>Occupancy</p>
          </li>
          <li>
           <StarRating rating={statisticsdata?.survey_statistics?.cleanliness?.percentage ?? 0} type="cleanlinessRatings"/>
            <p>Cleanliness</p>
          </li>
          <li>
            <span className="trash-icon-stats">
          <TrashIcon fillLevel={statisticsdata?.survey_statistics?.['trash cans']?.percentage ?? 0} />
            </span>
              <p>Trans Can(s)</p>
          </li>
          </ul>
          <ul className="satas-wrapper rating-align-text">
          <li>
          <StarRating rating={statisticsdata?.survey_statistics?.landscaping?.percentage ?? 0} type="landscapingRatings" />
              <p>Landscaping</p>
          </li>
          <li>
          <StarRating rating={statisticsdata?.survey_statistics?.signs?.percentage ?? 0} type="signageRatings"/>
              <p>Signs</p>
          </li>
          <li hidden></li>
        </ul>
        {/* <h2>Select a date range to generate a custom report.</h2>
        <ul className="date-picker-wrapper">
          <li>
            <label>Start Date <img src={downArrow} /></label>
            <DatePicker
              selected={startDate}
              // onChange={(date) => setStartDate(date)}
              onChange={(date) =>
                setStartDate(moment(date).tz(tz).toDate())
              }
              placeholderText="--/--/----"
              className="date-picker"
              maxDate={new Date()}
            />
          </li>
          <li>
            <label>End Date <img src={downArrow} /></label>
            <DatePicker
              selected={endDate}
              // onChange={(date) => setendDate(date)}
              // onChange={(date) =>
              //   setendDate(moment(date).tz(tz).toDate())
              // }
              onChange={(date) => {
                const newDate = moment(date).tz(tz);
                newDate.set({ hour: 23, minute: 59, second: 59 });
                setendDate(newDate.toDate());
            }} 
              placeholderText="--/--/----"
              className="date-picker"
              minDate={startDate}
              maxDate={new Date()}
              disabled={!startDate}
            />
          </li>
          <li>
            <button className="btn export-btn">Export Report</button>
          </li>
        </ul> */}
      </div>
      <Modal show={show} onHide={handleClose} className="add-permit contact-support">
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>

        </Modal.Header>
        <Modal.Body>
          <h5>"A member from our team will be in touch within 24 hours!”</h5>
          <Form className="row">
            <Form.Group
              className="mb-3 col-12"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Control
                type="text"
                placeholder="Subject"
                autoFocus
                // required
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mb-3 col-12"
              controlId="exampleForm.ControlInput1"
            >
              <textarea
                type="textarea"
                placeholder="Type your message here..."
                rows="7"
                cols="50"
                autoFocus
                onChange={(e) => setAddress(e.target.value)}
              />
            </Form.Group >
            <Form.Group
              className="mb-3 col-12 add-permit"
              controlId="exampleForm.ControlTextarea1"
            >
              <button className="btn DarkBtn" onClick={(e) => onAdd(e)}>
                Submit
              </button>
            </Form.Group>
            <Form.Group className="mt-3">
              {/* <div className="row">
                <div className="col-lg-12 text-center d-flex justify-content-center">
                  <span className="CustomMarg">
                    <p>
                      <img src={IcMail} alt="" className="MailIcon" />
                      Email ID:
                      <a href="mailto:admin@taggrsolutions.com"> admin@taggrsolutions.com</a>
                    </p>
                  </span>

                  <span className="CustomMarg">
                    <p>
                      <img src={IcCall} alt="" className="MailIcon" />
                      Contact:
                      <a href="mailto:admin@ts.com"> +1-202-555-0147</a>
                    </p>
                  </span>
                </div>
              </div> */}
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </section>
  );
}
