import { connect } from "react-redux";
import { lotprovider, user, getAllManagers, searchForOperator } from "../../store/home/duck";
import ManagerComponent from "./component";

const ManagerContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    user,
    lotprovider,
    getAllManagers,
    searchForOperator
  }
)(ManagerComponent);

export default ManagerContainer;
