import { connect } from "react-redux";
import { lotprovider, addlots, getLotsById, getProviders, getOffenceByOperator, user, getActiveOffenceByOperator } from "../../store/home/duck";
import LotEdit from "./edit_lot_component";

const LotEditContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    lotprovider,
    addlots,
    user,
    getOffenceByOperator,
    getProviders,
    getLotsById,
    getActiveOffenceByOperator
  }
)(LotEdit);

export default LotEditContainer;
