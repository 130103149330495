import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Details from "../../../images/details.svg";
import leftArrow from "../../../images/left-arrow.svg";
import { setAlert } from "../../../store/actioncreator";
import Header from "../Citations-Header/container";
import image from "../../../images/image.svg";
import { validReconcileStatuses } from "../../../utils";

const AdminDisputedCitationInfomration = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const [data, setdata] = useState({});

  const callApi = async () => {
    try {
      const id = params.id;
      const { value } = await props.get_disputed_citation_by_id(id);
      if (value.success) {
        setdata(value?.disputedCitation);
      } else {
        dispatch(setAlert("error", value?.message));
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
  };

  const hideMenu = () => {
    var element = document.getElementById("profile-wrapper");
    var menu = document.getElementById("test_menu");
    element.classList.add("left-wrapper");
    menu.classList.remove("showmenu");
  };

  const DisputedCitationDetail = (
    label,
    value,
    confidence,
    message,
    hideMessage = false,
  ) => {
    return (
      <div className="details-list">
        <label>{label}</label>
        <div className="field-with-confidence">
          <input
            value={value || "Nil"}
            type="text"
            id="text"
            className="form-control"
            disabled
          />
          {confidence && <p>{confidence}%</p>}
        </div>
        {!hideMessage && (
          <div
            className="disputed-reason"
            dangerouslySetInnerHTML={{ __html: message || "Nil" }}
          ></div>
        )}
      </div>
    );
  };

  useEffect(() => {
    callApi();
    hideMenu();
  }, []);

  return (
    <>
      <section className="adminCitations">
        <Header />
        <div className="citationsSection paddzero" id="profile-wrapper">
          <div className="title-wrapper">
            <div className="d-flex align-items-center">
              <img
                src={leftArrow}
                className="left-arrow"
                onClick={() => navigate(-1)}
              />
              <h4>Details</h4>
            </div>
          </div>
          <div className="cirtation-wrapper">
            <div className="details-block-wrapper">
              <h6>
                <img src={Details} />
                Disputed Citation Details
              </h6>
            </div>
            {data?.reconciliation_data?.is_reconciled ||
            validReconcileStatuses.includes(data?.status) ? (
              <React.Fragment>
                <div className="details-wrapper-block" style={{ gap: "40px" }}>
                  {/* {DisputedCitationDetail(
                    "Notice Number",
                    data?.notice_number ?? "",
                    data?.reasons?.noticeNumber?.confidence,
                    data?.reasons?.noticeNumber?.message,
                  )} */}
                  {DisputedCitationDetail(
                    "Notice Date",
                    data?.notice_issue_date ?? "",
                    data?.reasons?.noticeDate?.confidence,
                    data?.reasons?.noticeDate?.message,
                  )}
                  {DisputedCitationDetail(
                    "License Plate",
                    data?.license_plate ?? "",
                    data?.reasons?.licensePlate?.confidence,
                    data?.reasons?.licensePlate?.message,
                  )}
                  {DisputedCitationDetail(
                    "Receipt Date",
                    data?.receipt_date?.date || "Nil",
                    data?.reasons?.receiptDate?.confidence,
                    data?.reasons?.receiptDate?.message &&
                      data?.reasons?.parkingSession?.data?.startTime &&
                      data?.reasons?.parkingSession?.data?.endTime
                      ? data?.reasons?.receiptDate?.message +
                          `<br/><br/>Session Start Time: ${data?.reasons?.parkingSession?.data?.startTime}<br/>Session End Time: ${data?.reasons?.parkingSession?.data?.endTime}<br/>Notice Issued Time: ${data?.reasons?.parkingSession?.data?.noticeIssuedTime}`
                      : "Nil",
                  )}
                  {DisputedCitationDetail(
                    "Overall Confidence (%)",
                    data?.reconciliation_data?.confidence_overall ?? "Nil",
                    null,
                    null,
                    true,
                  )}
                </div>
                <div
                  className="matched-proof-images"
                  style={{ marginTop: "20px" }}
                >
                  {!!data?.proof_image?.url && (
                    <div>
                      <h6>
                        <img src={image} />
                        Proof Uploaded By User
                      </h6>
                      <img src={data?.proof_image?.url} />
                    </div>
                  )}
                  {data?.matched_proof_data?.proof_stored_on_lot && (
                    <div>
                      <h6>
                        <img src={image} />
                        Proof Stored On Lot
                      </h6>
                      <img
                        src={data?.matched_proof_data?.proof_stored_on_lot}
                      />
                    </div>
                  )}
                </div>
                <div className="details-wrapper-block">
                  {DisputedCitationDetail(
                    "Proof Matched",
                    data?.reasons?.proofMatched?.text ?? "",
                    data?.reasons?.proofMatched?.confidence,
                    data?.reasons?.proofMatched?.message,
                  )}
                </div>
              </React.Fragment>
            ) : data?.reconciliation_data?.error ? (
              <div className="details-block-wrapper">
                <p>{data?.reconciliation_data?.error}</p>
              </div>
            ) : !data?.license_plate ? (
              "User didn't enter the license plate"
            ) : !data?.point_of_contact ? (
              "User didn't enter the phone number"
            ) : !data?.notice_issue_date ? (
              "User didn't enter the notice issued date"
            ) : !data?.reconciliation_data?.proof_submitted ? (
              "User didn't submit the proof image"
            ) : (
              "Unknown error occured, thus no action was performed on this."
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminDisputedCitationInfomration;
