import { connect } from "react-redux";
import { logins, user, allCitations, searchallCitations, refund_citation, void_citation, send_sms, getAllLotsList } from "../../../store/home/duck";
import AdminCitationsComponent from "./admin_citation_component";

const AdminCitationsContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    logins,
    user,
    allCitations,
    searchallCitations,
    refund_citation,
    void_citation,
    send_sms,
    getAllLotsList
  }
)(AdminCitationsComponent);

export default AdminCitationsContainer;
